<div class="chat" [class.chat--disabled]="resetRequired" [ngStyle]="{'height': height}">
  <div class="chat__body">

    <div class="chat__reset" *ngIf="resetRequired">
      <p style="margin-bottom: 10px">Agents were updated. Session reset is recommended to use the updates.</p>
      <button class="button--primary button--sm" (click)="reset()">Reset</button>
    </div>

    <div class="chat__messages" #chatContainer>
      <ng-container *ngFor="let message of messages; let i = index;">
        <div class="chat__message chat__message--{{message.role}}">
          <div class="chat__message-header">
            <div class="chat__message-name" *ngIf="message.role === 'assistant'">
              <img [src]="message.agent ? message.agent.icon : assistantAvatar" alt="Assistant Avatar" class="chat__avatar">
              {{!!message.agent ? message.agent.name : assistantNickname}}
            </div>
            <div class="chat__message-replay" *ngIf="replayEnabled && i !== 0 && i !== (messages.length - 1) && message.role === 'assistant'">
              <button class="button--primary button--sm"
                      [class.button--loading]="isLoading"
                      (click)="replay(i)">
                Replay to here
              </button>
            </div>
          </div>
          <div class="chat__message-name" *ngIf="message.role === 'user'">User</div>
          <div class="chat__message-text">
            <markdown class="markdown" [data]="message.text | prepareMessage"></markdown>
            <button class="button button--primary button--sm"
                    (click)="exportMessage(message)"
                    style="margin-top: 10px;"
                    *ngIf="(message.text | prepareMessage).includes('<table>')">
              Export
            </button>
          </div>
        </div>
      </ng-container>
      <div class="chat__message chat__message--loading chat__message--assistant" *ngIf="isLoading">
        <div class="chat__message-name">
          <img *ngIf="assistantAvatar" [src]="assistantAvatar" alt="Assistant Avatar" class="chat__avatar">
          <img *ngIf="!assistantAvatar" src="/assets/img/chat.png" alt="Assistant Avatar" class="chat__avatar">
          {{assistantNickname}}
        </div>
        <div class="chat__message-text">
          <markdown [data]="progress$ | async"></markdown>
        </div>
      </div>
    </div>
    <form *ngIf="!isReadonly" (ngSubmit)="sendMessage$.emit()">
      <div class="fancy-input__wrapper chat__input-wrapper"
           [class.fancy-input__wrapper--active]="!!message.value">
        <input [formControl]="message"
               class="fancy-input"
               #searchInput
               type="text"
               placeholder="Enter your message">

        <label for="file-upload" class="chat__file-upload" *ngIf="fileUploadOn">
          <div *ngIf="selectedFiles?.length" class="chat__file-count">{{selectedFiles?.length}}</div>
          <img src="/assets/img/upload-file.svg" alt="">
          <input id="file-upload" #fileInput type="file" (change)="onFileSelected($event)" multiple/>
        </label>

        <button type="submit" class="fancy-input__button">
          <img *ngIf="!isLoading" class="fancy-input__icon" src="/assets/img/send.svg"
               style="transform: rotate(45deg);" alt="">
          <img *ngIf="isLoading" class="fancy-input__spinner" alt="" src="/assets/img/spinner.svg">
        </button>
      </div>
    </form>
  </div>
</div>
