import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from "../auth.service";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { Router } from "@angular/router";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { HttpClient, HttpParams } from "@angular/common/http";
import { interval, Subscription, take, tap } from "rxjs";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  animations: [
    trigger('slideAnimation', [
      state('void', style({
        opacity: 0,
        transform: 'translateY(-10%)'
      })),
      state('*', style({
        opacity: 1,
        transform: 'translateY(0)'
      })),
      transition(':enter', [
        animate('500ms ease-in')
      ]),
      transition(':leave', [
        animate('0ms ease-out', style({
          opacity: 0,
        }))
      ])
    ])
  ]
})
export class AuthComponent implements OnInit {
  texts = [
    {title: 'Unlock the Full Potential of AdSense', body: 'Connect your Google AdSense to let AI lift your campaigns to the New Heights'},
    {title: 'Data-Driven Decisions with Portal.ai', body: 'Transform the data of your business into proactive daily insights'},
    {title: 'Let AI Turn Feedback Into Future', body: 'The AI algorithm tailors the questionnaire to your users based on their engagement pattern with the product'},
    {title: 'Data-Driven Profit Monitoring', body: 'Monitor, analyze, and optimize your financial health'},
  ];
  currentIndex = 0;
  displayedText = '';
  typingInterval = 50;
  credentials = this.fb.group({
    email: ['', Validators.required],
    password: ['', Validators.required]
  });
  beta = this.fb.group({
    email: ['', Validators.required]
  });

  step: 'init' | 'policy-checkboxes' |'login' | 'beta' | 'beta-success' = 'init';
  policiesAccepted = new FormControl(false);

  private typingSubscription?: Subscription;
  private rotationSubscription?: Subscription;

  constructor(private authService: AuthService,
              private http: HttpClient,
              private fb: FormBuilder) {
  }

  joinBeta() {
    this.beta.markAllAsTouched();
    if (this.beta.invalid) {
      return;
    }
    const body = new HttpParams()
      .set('form-name', 'beta')
      .append('email', this.beta.value.email as string)

    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};

    this.http.post('/', body.toString(), {headers}).subscribe(
      () => {
        this.step = 'beta-success'
      },
      () => {
        this.step = 'beta-success'
      },
    );
  }

  ngOnInit() {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.resumeIntervals();
      } else {
        this.pauseIntervals();
      }
    });
    this.typeText();
    this.startIntervals();
  }


  typeText() {
    const currentText = this.texts[this.currentIndex].body;
    this.displayedText = '';
    let i = 0;

    interval(this.typingInterval).pipe(
      take(currentText.length),
      tap(() => this.displayedText += currentText[i++]),
      untilDestroyed(this),
    ).subscribe();
  }

  startIntervals() {
    const longestText = this.texts.reduce((a, b) => a.body.length > b.body.length ? a : b);

    this.rotationSubscription = interval(this.typingInterval * longestText.body.length + 2000).pipe(
      tap(() => {
        this.currentIndex = (this.currentIndex + 1) % this.texts.length;
        this.typeText();
      }),
      untilDestroyed(this),
    ).subscribe();
  }

  resumeIntervals() {
    this.startIntervals();
  }

  pauseIntervals() {
    if (this.typingSubscription) {
      this.typingSubscription.unsubscribe();
    }
    if (this.rotationSubscription) {
      this.rotationSubscription.unsubscribe();
    }
  }
}
