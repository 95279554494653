import { Component, OnInit } from "@angular/core";
import { AuthService } from "./auth/auth.service";
import { Router } from "@angular/router";
import { map, switchMap } from "rxjs/operators";
import { filter, take } from "rxjs";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BusinessService } from "../openapi/portal/services/business.service";
import { BusinessStateService } from "./profile/business/business-state.service";


const noSidebarPaths = [
  '/playground',
  '/privacy-policy',
  '/auth',
  '/attach/shopify',
  '/amazon',
  '/attach/google_ads',
];

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isLoggedIn$ = this.authService.isLoggedIn$.pipe(
    map(isLoggedIn => isLoggedIn || this.router.url === '/privacy-policy')
  );

  isSidenavRequired$ = this.router.events.pipe(
    map(() => !noSidebarPaths.includes(this.router.url)),
  );

  constructor(private router: Router,
              private businessService: BusinessService,
              private businessStateService: BusinessStateService,
              private authService: AuthService) {
  }

  ngOnInit() {
    this.authService.isLoggedIn$.pipe(
      filter((isLoggedIn) => isLoggedIn),
      take(1),
      switchMap(() => this.businessService.getBusiness()),
      untilDestroyed(this),
    ).subscribe((business) => {
      this.businessStateService.setBusiness(business.business);
    });
  }

}
