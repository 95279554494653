import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prepareMessage',
})
export class PrepareMessagePipe implements PipeTransform {

  transform(raw: string): string {
    try {
      const parsed = JSON.parse(raw);
      if (Array.isArray(parsed)) {
        return this.buildTable(parsed);
      } else {
        return raw;
      }
    } catch (e) {
      return raw;
    }
  }

  private buildTable(list: any[]) {
    let table = '<table>';
    table += '<thead><tr>';
    for (let header in list[0]) {
      table += `<th>${header}</th>`;
    }
    table += '</tr></thead>';

    list.forEach(row => {
      table += '<tr>';
      for (let key in row) {
        table += `<td>${row[key]}</td>`;
      }
      table += '</tr>';
    });

    table += '</table>';
    return table;
  }

}
