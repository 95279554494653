import { Component } from '@angular/core';

@Component({
  selector: 'app-amazon',
  templateUrl: './amazon.component.html',
  styleUrls: ['./bootstrap.scss', './amazon.component.scss']
})
export class AmazonComponent {

}
