import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SessionComponent } from "./session/session.component";
import { RoutinesComponent } from "./routines/routines.component";
import { NewRoutineComponent } from "./routines/new-routine/new-routine.component";
import { hasBusinessGuard } from "./profile/business/has-business.guard";
import { AuthComponent } from "./auth/auth/auth.component";
import { AgentComponent } from "./agent/agent.component";
import { PlaygroundComponent } from "./playground/playground.component";
import { AuthorizedOnlyGuard } from "./auth/authorized-only.guard";
import { NotAuthorizedOnlyGuard } from "./auth/not-authorized-only.guard";
import { NotFoundComponent } from "./not-found/not-found.component";
import {PrivacyPolicyComponent} from "./privacy-policy/privacy-policy.component";
import { ProfileComponent } from "./profile/profile/profile.component";
import { AboutUsComponent } from "./about-us/about-us.component";
import { TermsOfServiceComponent } from "./terms-of-service/terms-of-service.component";
import { RoutineComponent } from "./routines/routine/routine.component";
import { AttachShopifyComponent } from "./profile/attach-shopify/attach-shopify.component";
import { AttachGoogleAdsComponent } from "./profile/attach-google-ads/attach-google-ads.component";
import {AmazonComponent} from "./amazon/amazon.component";

export const notFoundPath = '/not-found';
export const defaultAgentUrl = '/chat';
export const authUrl = '/auth';

const routes: Routes = [
  {path: '', component: AboutUsComponent, canActivate: [NotAuthorizedOnlyGuard]},
  {path: authUrl.replace('/', ''), component: AuthComponent, canActivate: [NotAuthorizedOnlyGuard]},
  {path: 'profile', canActivate: [AuthorizedOnlyGuard], component: ProfileComponent},
  {path: 'playground', component: PlaygroundComponent, canActivate: [AuthorizedOnlyGuard]},
  {path: notFoundPath.replace('/', ''), component: NotFoundComponent},
  {path: 'privacy-policy', component: PrivacyPolicyComponent},
  {path: 'amazon', component: AmazonComponent},
  {path: 'terms-of-service', component: TermsOfServiceComponent},
  {path: 'attach', canActivate: [hasBusinessGuard], children: [
      {path: 'shopify', component: AttachShopifyComponent},
      {path: 'google_ads', component: AttachGoogleAdsComponent},
    ]},
  {path: ':agentId', canActivate: [AuthorizedOnlyGuard], children: [
      {path: '', component: AgentComponent},
      {path: 'session', component: SessionComponent, canActivate: [hasBusinessGuard]},
      {
        path: 'routines', canActivate: [hasBusinessGuard], children: [
          {path: '', component: RoutinesComponent},
          {path: 'new', component: NewRoutineComponent},
          {path: ':routineId', component: RoutineComponent},
        ],
      },
  ]},
  {path: '**', redirectTo: notFoundPath.replace('/', ''), pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
