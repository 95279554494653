<div class="integration" [class.integration--loading]="isLoading">
  <h1 class="h4">Amazon</h1>

  <div *ngIf="integration; else connect">
    <p class="integration__line" *ngIf="integration.name">Name: {{ integration.name }}</p>
    <button class="button button--sm button--error" (click)="detach$.emit(integration)">Disconnect</button>
  </div>

  <ng-template #connect>
    <p class="integration__description">
      Integrate your Amazon Shop to get a comprehensive view of your online sales.
      Track inventory, understand customer buying patterns, and boost your revenue with strategic product placements and promotions.
    </p>

    <button class="button button--amazon button--has-icon"
            [class.button--loading]="isLoading"
            (click)="attach()">
      <img class="button__icon" src="/assets/img/amazon.png" alt="">
      Connect Amazon
    </button>
  </ng-template>
</div>
