import { Component, OnInit } from '@angular/core';
import { RoutineWithSessions } from "../../openapi/portal/models/routine-with-sessions";
import { SessionStatus } from "../../openapi/portal/models/session-status";
import { RoutinesService } from "../../openapi/portal/services/routines.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-routines',
  templateUrl: './routines.component.html',
  styleUrl: './routines.component.scss'
})
export class RoutinesComponent implements OnInit {
  routines: RoutineWithSessions[] = []
  newSessionRunning = false;
  SessionStatus = SessionStatus;
  agentId = '';

  constructor(private routinesService: RoutinesService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.agentId = this.route.snapshot.paramMap.get("agentId") as string;
    this.routinesService.getRoutines({agent_id: this.agentId}).subscribe((routines) => {
      this.routines = routines.routines;
    })
  }

  runSession(routine: RoutineWithSessions) {
    this.newSessionRunning = true;
    this.routinesService.runAsyncRoutine({routine_id: routine._id!}).subscribe((routines) => {
      this.newSessionRunning = false;
      this.routines = routines.routines;
    });
  }
}
