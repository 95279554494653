import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Integration } from "../../../openapi/portal/models/integration";
import { CreateIntegrationRequest } from "../../../openapi/portal/models/create-integration-request";
import { IntegrationType } from "../../../openapi/portal/models/integration-type";
import { switchMap, tap } from "rxjs";
import { BusinessStateService } from "../business/business-state.service";
import { IntegrationsService } from "../../../openapi/portal/services/integrations.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AuthReturnUrlsService } from "../auth-return-urls.service";

@UntilDestroy()
@Component({
  selector: 'app-attach-google-ads',
  templateUrl: './attach-google-ads.component.html',
  styleUrls: ['./attach-google-ads.component.scss', '../integrations/integrations.component.scss']
})
export class AttachGoogleAdsComponent {
  @Input() integration?: Integration;
  @Output() detach$ = new EventEmitter<Integration>();
  @Output() refresh$ = new EventEmitter<void>();
  isLoading = false;
  
  constructor(private businessState: BusinessStateService,
              private authReturnUrlsService: AuthReturnUrlsService,
              private integrationService: IntegrationsService) {
  }

  attach() {
    const business = this.businessState.getBusiness();
    if (!business) {
      return;
    }
    const body: CreateIntegrationRequest = {
      name: "Some Google Ads Account",
      type: IntegrationType.GoogleAds,
      business_id: business._id!,
      callback_urls: this.authReturnUrlsService.getReturnUrls(IntegrationType.GoogleAds),
    };
    this.isLoading = true;
    this.integrationService.initIntegration({body}).pipe(

      switchMap((response) => this.authenticate(response.integration._id!)),
    ).subscribe(
      () => this.refresh(),
      () => this.refresh()
    );
  }

  authenticate(integrationId: string) {
    return this.integrationService.authenticateIntegration({integration_id: integrationId}).pipe(
      tap((response) => {
        window.open(response.auth_url, "_self");
      }),
    )
  }
  private refresh() {
    this.refresh$.emit();
    this.isLoading = false;
  }
}
