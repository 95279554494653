import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Message } from "../../openapi/portal/models/message";
import { FormControl } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { BehaviorSubject, Observable } from "rxjs";
import { ExportMessageService } from "./export-message.service";


export interface Replay {
  lastUserMessage: string;
  lastAssistantMessageIndex: number;
}


@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html',
  styleUrl: './conversation.component.scss'
})
export class ConversationComponent implements AfterViewInit, OnChanges {
  @Input() messages: Message[] = [];
  @Input() assistantNickname: string | undefined = 'Assistant';
  @Input() assistantAvatar: string | undefined = '/assets/img/chat.png';
  @Input() isReadonly = false;
  @Input() message!: FormControl<string | null>;
  @Input() isLoading = false;
  @Input() fileUploadOn = false;
  @Input() height = '100vh';
  @Input() resetRequired = false;
  @Input() replayEnabled = false;
  @Input() progress$ = new BehaviorSubject<string>('Loading...');

  @Output() sendMessage$ = new EventEmitter<void>();
  @Output() filesSelected$ = new EventEmitter<Blob[]>();
  @Output() reset$ = new EventEmitter<void>();
  @Output() replay$ = new EventEmitter<Replay>();

  @ViewChild('searchInput') searchInput!: ElementRef;
  @ViewChild('chatContainer') chatContainer!: ElementRef;
  @ViewChild('fileInput') fileInput!: ElementRef;

  selectedFiles: Blob[] | undefined;

  constructor(private exportMessageService: ExportMessageService) {
  }

  onFileSelected(event: any) {
    this.selectedFiles = event.target.files;
    this.filesSelected$.emit(this.selectedFiles);
  }

  ngAfterViewInit() {
    this.searchInput.nativeElement.focus();
    this.searchInput.nativeElement.scrollIntoView()
  }

  ngOnChanges() {
    this.scrollToBottom();
    this.selectedFiles = []
    this.filesSelected$.emit([]);
    if (this.fileInput?.nativeElement) {
      this.fileInput.nativeElement.value = "";
    }
  }

  reset() {
    this.reset$.emit();
  }

  replay(lastAssistantMessageIndex: number) {
    const lastUserMessageIndex  = lastAssistantMessageIndex + 1;
    this.replay$.emit({
      lastUserMessage: lastUserMessageIndex <= this.messages.length ? this.messages[lastUserMessageIndex].text : '',
      lastAssistantMessageIndex: lastAssistantMessageIndex,
    });
  }

  exportMessage(message: Message) {
    this.exportMessageService.saveMessage(message);
  }

  private scrollToBottom(): void {
    setTimeout(() => {
      if (this.searchInput?.nativeElement) {
        this.searchInput.nativeElement.scrollIntoView()
      }
      if (this.chatContainer?.nativeElement) {
        this.chatContainer.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
      }
    }, 50);
  }
}
