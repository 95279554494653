import { Component, OnInit } from '@angular/core';
import { FormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Message } from "../../openapi/portal/models/message";
import { MessageRole } from "../../openapi/portal/models/message-role";
import { Session } from "../../openapi/portal/models/session";
import { Agent } from "../../openapi/portal/models/agent";
import { AgentsService } from "../../openapi/portal/services/agents.service";
import { SessionsService } from "../../openapi/portal/services/sessions.service";
import { combineLatest, Observable, of, switchMap, tap } from "rxjs";
import { BodyUpdateSession } from "../../openapi/portal/models/body-update-session";
import { BodyCreateSession } from "../../openapi/portal/models/body-create-session";
import { ConversationService } from "../conversation/conversation.service";


@UntilDestroy()
@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.scss']
})
export class SessionComponent implements OnInit {
  message = new FormControl<string>('');
  sessionId!: string;
  agentId!: string;
  isLoading = false;
  session: Session | undefined;
  agent: Agent | undefined;
  selectedFiles: Blob[] | undefined;
  hasError = false;
  messages: Message[] = [];
  progress$ = this.conversationService.progress$;

  constructor(private route: ActivatedRoute,
              private conversationService: ConversationService,
              private router: Router,
              private sessionService: SessionsService,
              private agentsService: AgentsService) {
  }


  ngOnInit() {
    combineLatest([this.route.paramMap, this.route.queryParamMap]).pipe(
      tap(([params, queryParams]) => {
        this.agentId = params.get('agentId') as string;
        this.sessionId = queryParams.get('sessionId') as string
      }),
      switchMap(() => this.initAgent(this.agentId)),
      switchMap(() => this.initSession(this.sessionId)),
      untilDestroyed(this),
    ).subscribe(
      () => {
        this.isLoading = false;
      },
      (e) => {
        this.isLoading = false;
        const text = e.error.detail || "Sorry, we couldn't answer this. Refresh the page and try again."
        this.hasError = true;
        this.messages.push({
          role: MessageRole.Assistant,
          text: text,
        })
      });
  }

  sendMessage(text: string | null) {
    if (!text || this.isLoading) {
      return;
    }

    this.message.setValue("");
    this.isLoading = true;

    const message: Message = {
      text: text,
      role: MessageRole.User,
    }

    this.messages.push(message);

    if (this.sessionId) {
      this.updateSession(text);
    } else {
      this.createSession(text);
    }
  }

  onFileSelected(files: Blob[]) {
    this.selectedFiles = files;
  }

  private initAgent(agentId: string) {
    return this.agentsService.getAgent({agent_id: agentId}).pipe(
      tap((agent) => {
        this.agent = agent;
        if (!this.sessionId) {
          this.messages = [{
            text: this.agent.welcome_message,
            role: MessageRole.Assistant,
          }]
          this.message.setValue(agent.user_initial_message);
        }
      })
    );
  }

  private createSession(prompt: string) {
    const body: BodyCreateSession = {
      text_prompt: prompt,
      agent_id: this.agentId,
      files: this.selectedFiles?.length ? Array.from(this.selectedFiles) : [],
    }
    this.sessionService.createAsyncSession({body}).pipe(
      switchMap((session) => this.waitSession(session.session_id)),
      untilDestroyed(this),
    ).subscribe((session) => {
      this.setSession(session);
      this.router.navigate(['.'], {
        relativeTo: this.route,
        queryParams: {sessionId: session._id},
        queryParamsHandling: 'merge',
      })
      this.isLoading = false;
    }, (e) => {
      this.isLoading = false;
      this.messages.push({
        role: MessageRole.Assistant,
        text: "Sorry, we couldn't answer this. Refresh the page and try again.",
      })
    });
  }

  private updateSession(prompt: string) {
    const body: BodyUpdateSession = {
      text_prompt: prompt,
      files: this.selectedFiles?.length ? Array.from(this.selectedFiles) : [],
    }
    this.sessionService.updateAsyncSession({session_id: this.sessionId, body}).pipe(
      switchMap((session) => this.waitSession(session.session_id)),
      untilDestroyed(this),
    ).subscribe((session) => {
      this.session = session;
      this.messages = session.messages;
      this.isLoading = false;
    });
  }

  private initSession(sessionId: string) {
    if (!this.sessionId) {
      return of(false);
    }
    return this.sessionService.getSession({session_id: sessionId}).pipe(
      tap((session) => {
          this.setSession(session);
          if (this.messages.length === 0) {
            this.message.setValue("Let's start now!");
          }
        }
      ));
  }

  private waitSession(sessionId: string): Observable<Session> {
    return this.conversationService.listenSession(sessionId).pipe(
      tap((session) => this.setSession(session)),
    );
  }

  private setSession(session: Session) {
    this.session = session;
    this.messages = session.messages;
  }
}
