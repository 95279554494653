import { Pipe, PipeTransform } from '@angular/core';

export const intervals = [
  {value: "0 0 * * *", label: 'Every midnight'},
  {value: "0 * * * *", label: 'Every hour'},
  {value: "*/5 * * * *", label: 'Every five minutes'},
];

@Pipe({
  name: 'formatInterval',
})
export class FormatIntervalPipe implements PipeTransform {

  transform(cron: string): string {
    const interval = intervals.find(i => i.value === cron);
    if (interval) {
      return interval.label;
    } else {
      return cron;
    }
  }

}
