<div class="login">
  <amplify-authenticator>
    <ng-template
      amplifySlot="authenticated"
      let-user="user"
      let-signOut="signOut"
    >
      <button (click)="signOut()">Sign Out</button>
    </ng-template>
  </amplify-authenticator>
</div>
