<div class="page">
  <h1 class="h1">{{ routine ? routine.name : '' }}</h1>


  <div class="routine" *ngIf="routine">


    <div>Name: {{ routine.name }}</div>
    <div>Requirements: {{ routine.requirements }}</div>
    <div>Interval: {{ routine.interval | formatInterval }}</div>
    <div>Sessions: <span *ngIf="routine.sessions.length === 0">No sessions yet</span></div>
    <div class="advanced-table__wrapper" *ngIf="routine.sessions.length">
      <table class="advanced-table advanced-table--full-width">
        <thead>
        <tr>
          <td>Updated At</td>
          <td>Latest Message</td>
          <td>Status</td>
          <td></td>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let session of routine.sessions">
          <td>{{ session.updated_at | date: 'medium' }}</td>
          <td>
            <div class="advanced-table__content--limited">
              {{ session.status === SessionStatus.Running ? 'In Progress' : session.messages[session.messages.length - 1].text }}
            </div>
          </td>
          <td>{{ session.status }}</td>
          <td>
            <a *ngIf="session._id"
               [routerLink]="['/', session.agent_id, 'session']"
               [queryParams]="{sessionId: session._id}"
               class="button--primary button--sm">Show Chat</a>
            <button *ngIf="!session._id" class="button button--loading button--sm">Building Chat</button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <button class="button--primary button--sm"
            style="margin-right: 10px"
            [class.button--loading]="newSessionRunning"
            (click)="runSession(routine)">Run Now
    </button>

    <button class="button button--error button--sm" (click)="deleteRoutine(routine)">Delete Routine</button>


    <div *ngIf="routine.sessions.length">
      <h4 class="h4">Trend</h4>
      <p class="trend__status trend__status--{{routine.trend.status}}">{{ routine.trend.status }}
        <button *ngIf="['critical', 'warning'].includes(routine.trend.status.toLowerCase())" class="button--primary button--sm">Ask your users with UXmate</button></p>
      <p style="margin-bottom: 10px">Recommendation: {{ routine.trend.short_recommendation }}</p>
      <p>Description: {{ routine.trend.description }}</p>
    </div>

    <img src="/assets/img/chart.gif" alt="">

  </div>
</div>
