/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AsyncSessionResponse } from '../../models/async-session-response';
import { BodyCreateAsyncSession } from '../../models/body-create-async-session';

export interface CreateAsyncSession$Params {
      body: BodyCreateAsyncSession
}

export function createAsyncSession(http: HttpClient, rootUrl: string, params: CreateAsyncSession$Params, context?: HttpContext): Observable<StrictHttpResponse<AsyncSessionResponse>> {
  const rb = new RequestBuilder(rootUrl, createAsyncSession.PATH, 'post');
  if (params) {
    rb.body(params.body, 'multipart/form-data');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<AsyncSessionResponse>;
    })
  );
}

createAsyncSession.PATH = '/sessions/async';
