<div class="amazon">
  <header class="navbar-area">
    <div class="container">
      <nav class="navbar">
        <a class="navbar-brand" routerLink="/auth">
          <!--?xml version="1.0" standalone="no"?-->

          <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="109.000000pt" height="109.000000pt"
               viewBox="0 0 109.000000 109.000000" preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,1.000000) scale(0.100000,-0.100000)">
              <path d="M1002 1025 c-49 -94 -45 -91 -85 -60 -136 103 -400 55 -599 -109
              -118 -98 -221 -262 -270 -431 -28 -98 -32 -304 -7 -369 l15 -38 33 66 c34 65
              44 76 56 56 4 -6 30 -22 58 -37 184 -94 504 26 677 254 65 85 133 224 161 328
              28 103 33 310 8 365 l-16 35 -31 -60z m-296 -254 c125 -76 45 -309 -139 -401
              -111 -56 -212 -37 -249 46 -17 39 -18 52 -9 95 24 109 97 199 204 250 74 35
              146 39 193 10z"></path>
            </g>
          </svg>
        </a>

        <div class="header-btn">
          <a routerLink="/auth" class="main-btn btn-hover">Try It Now</a>
        </div>
      </nav>
    </div>
  </header>
  <!-- ========================= header end ========================= -->

  <!-- ========================= hero-section start ========================= -->
  <section id="home" class="hero-section">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-xl-6 col-lg-6 col-md-10">
          <div class="hero-content">
            <h1>
              Your Amazon's Selling Partner
            </h1>
            <p>
              At Portal.ai, we've developed a sophisticated integration with Amazon's Selling Partner API,
              ensuring a smooth and efficient experience for our users. Our platform is built to enhance the
              capabilities of your Amazon store by leveraging real-time data analytics, providing insights that drive
              growth and efficiency.
            </p>

            <a routerLink="/auth" class="main-btn btn-hover">Start Now</a>
          </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-6">
          <div class="hero-image text-center text-lg-end">
            <img src="/assets/img/about-us/hero/hero-image2.svg" alt="">
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ========================= hero-section end ========================= -->

  <!-- ========================= feature-section start ========================= -->
  <section id="features" class="feature-section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="section-title text-center">
          <h2 class="mb-40">
            Features You'll Get
          </h2>
          <div class="features">
            <div class="features__list">
              <div class="features__feature">
                <img class="features__feature-img" src="/assets/img/amazon/sales_per_country.jpg" alt="">
                <div>
                  <b class="features__feature-title">Per-Country Deep Analytics</b>
                  <p class="features__feature-description">Unlock the power of localization
                    with our Per Country Deep
                    Analytics. Dive into country-specific data to tailor your marketing, inventory, and shipping
                    strategies. Understand regional preferences and demand patterns to effectively expand your global
                    reach and optimize international sales
                  </p>
                </div>
              </div>
              <div class="features__feature">
                <img class="features__feature-img" src="/assets/img/amazon/per_sku.jpg" alt="">
                <div>
                  <b class="features__feature-title">Per-SKU Reports</b>
                  <p class="features__feature-description">Gain granular visibility into your business
                    with our detailed Per-SKU
                    Reports. Understand performance at the product level, enabling data-driven decisions to enhance your
                    inventory strategy and boost sales
                  </p>
                </div>
              </div>
              <div class="features__feature">
                <img class="features__feature-img" src="/assets/img/amazon/chat.jpg" alt="">
                <div>
                  <b class="features__feature-title">AI-Based Chat about your Insights</b>
                  <p class="features__feature-description">Get instant, intelligent support with our AI-Based Chat. Ask
                    any question about your sales, inventory, or customer feedback insights and receive immediate,
                    data-backed answers to help guide your business decisions
                  </p>
                </div>
              </div>
              <div class="features__feature">
                <img class="features__feature-img" src="/assets/img/amazon/campaigns.jpg" alt="">
                <div>
                  <b class="features__feature-title">Unified Sales and Marketing Dashboard</b>
                  <p class="features__feature-description">
                    Maximize your e-commerce potential with our Unified Sales and Marketing Dashboard, a game-changer
                    for Amazon sellers. By seamlessly connecting your Seller's Center data with Amazon Ads analytics,
                    this platform empowers you to harmonize your sales strategies with your marketing efforts. Gain
                    unparalleled insights into how your ads influence sales, optimize your campaigns with direct
                    feedback from your sales performance, and make data-driven decisions that boost your ROI. Streamline
                    your operations, from inventory management to ad spend, all in one intuitive interface. Elevate your
                    online business to new heights by bridging the gap between sales and marketing
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- ========================= feature-section-1 start ========================= -->
  <section id="feature-1" class="feature-section-1 mt-60 pt-40">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 order-last order-lg-first">
          <div class="feature-image text-center text-lg-start">
            <img src="/assets/img/about-us/feature/feature-image-3.svg" alt="">
          </div>
        </div>
        <div class="col-lg-6 col-md-10">
          <div class="feature-content-wrapper">
            <div class="section-title">
              <h2 class="mb-20">How It Works?</h2>
              <p class="mb-10">1. The business owner connects Portal.ai to his Amazon account.</p>
              <p class="mb-10">2. Portal.ai pulls in various data points from Amazon Sellers Center and Amazon Ads.
                This includes sales data, product performance, ads performance.</p>
              <p class="mb-10">3. Beyond just data analysis, Portal.ai provides actionable recommendations. This could
                be tips for inventory management, pricing strategies, or marketing tactics.</p>
              <a routerLink="/auth" class="main-btn btn-hover">Try It Now</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ========================= feature-section-1 end ========================= -->

  <!-- ========================= feature-section-2 start ========================= -->
  <section id="feature-2" class="feature-section-2 pt-40">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-10">
          <div class="feature-content-wrapper">
            <div class="section-title">
              <h2 class="mb-20">Our Commitment to Compliance</h2>
              <p class="mb-10">
                <b>Transparency and Honesty</b>: We're transparent about the data we access and its usage.
                Our users are always informed about what data is being used and for what purpose.
              </p>
              <p class="mb-10">
                <b>Data Privacy and Protection</b>: Adhering to global data privacy laws like GDPR, we ensure the utmost
                security and confidentiality of your data.
              </p>
              <p class="mb-10">
                <b>Secure Data Management</b>: We strictly manage account access and data sharing, aligning with
                Amazon's security protocols. We never share access keys or request sensitive information in violation of
                Amazon's guidelines.
              </p>
              <p class="mb-10">
                <b>Responsible Data Usage</b>: We use data solely for acceptable purposes, ensuring no misuse or
                unauthorized aggregation of data.
              </p>
              <a routerLink="/auth" style="background: #ff9900" class="main-btn btn-hover">Read More</a>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="feature-image text-lg-end">
            <img src="/assets/img/about-us/feature/feature-image-4.svg" alt="">
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ========================= feature-section-2 end ========================= -->

  <!-- ========================= feature-section-3 start ========================= -->
  <section id="feature-3" class="feature-section-1 mt-60 pt-40">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 order-last order-lg-first">
          <div class="feature-image text-center text-lg-start">
            <img src="/assets/img/about-us/feature/feature-image-3.svg" alt="">
          </div>
        </div>
        <div class="col-lg-6 col-md-10">
          <div class="feature-content-wrapper">
            <div class="section-title">
              <h2 class="mb-20">Dedicated to Your Growth</h2>
              <div class="mb-30">
                <p class="mb-20">
                  With continuous data analysis, Portal.ai evolves with your business.
                  Expect increasingly tailored insights and strategies, all while keeping your data private and never
                  aggregated or shared.
                </p>
                <a routerLink="/auth" class="main-btn btn-hover">Try It Now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- ========================= footer start ========================= -->
  <footer class="footer pt-60">
    <div class="container">
      <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-10">
          <div class="footer-widget">
            <p class="desc">
              PORTAL AI INC, address: 541 Jefferson Ave Ste 100 Redwood City, California San Mateo County, 94063, US
            </p>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-10">
          <div class="footer-widget">
            <a routerLink="/privacy-policy" class="row">Privacy Policy</a>
            <a routerLink="/auth" class="row">Start Now</a>
          </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6">
        </div>
      </div>
    </div>
  </footer>
  <!-- ========================= footer end ========================= -->
</div>
