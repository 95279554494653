import { Injectable } from '@angular/core';
import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Router } from "@angular/router";
import { authUrl, defaultAgentUrl } from "../app-routing.module";

export interface AuthState {
  isLoggedIn: boolean;
  username: string | null;
  token: string | null
}

const initialAuthState = {
  isLoggedIn: false,
  username: null,
  token: null
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly _authState = new BehaviorSubject<AuthState | undefined>(undefined);

  /** AuthState as an Observable */
  readonly auth$ = this._authState.asObservable().pipe(
    filter(v => v !== undefined)
  ) as Observable<AuthState>;

  /** Observe the isLoggedIn slice of the auth state */
  readonly isLoggedIn$ = this.auth$.pipe(map(state => state.isLoggedIn));
  readonly token$ = this.auth$.pipe(map(state => state.token));
  readonly username$ = this.auth$.pipe(map(state => state.username));
  readonly avatar$ = this.auth$.pipe(map(state => "/assets/img/avatar-placeholder.png"));

  constructor(private router: Router) {
    // Get the user on creation of this service
    Auth.currentAuthenticatedUser().then(
      (user: any) => this.setUser(user),
      _err => this._authState.next(initialAuthState)
    );

    // Use Hub channel 'auth' to get notified on changes
    Hub.listen('auth', ({ payload: { event, data, message } }) => {
      console.log('event: ', event);
      if (["autoSignIn", "signIn", "cognitoHostedUI"].includes(event)) {
        this.setUser(data);
        this.router.navigate([defaultAgentUrl]);
      } else if (event === 'tokenRefresh') {
        console.log("token refresh");
      } else if (event === 'signOut') {
        this._authState.next(initialAuthState);
        this.router.navigate([authUrl]);
      } else {
        this._authState.next(initialAuthState);
      }
    });
  }

  isLoggedIn(): boolean {
    return !!this._authState.value && this._authState.value.isLoggedIn;
  }

  async logout() {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  private setUser(user: any) {
    if (!user) {
      return;
    }

    const {
      username
    } = user;

    const token = user.getSignInUserSession()?.getAccessToken().getJwtToken();

    this._authState.next({ isLoggedIn: true, username, token });
  }
}
