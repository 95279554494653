import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AgentsService } from "../../openapi/portal/services/agents.service";
import { Agent } from "../../openapi/portal/models/agent";
import { SessionsService } from "../../openapi/portal/services/sessions.service";
import { GroupedSessionsResponse } from "../../openapi/portal/models/grouped-sessions-response";
import { BusinessStateService } from "../profile/business/business-state.service";
import { SidenavService } from "./sidenav.service";
import { startWith, switchMap, filter, map } from "rxjs";
import { ActivatedRoute, ActivationEnd, NavigationEnd, NavigationStart, Router, RoutesRecognized, Scroll } from "@angular/router";
import { BusinessService } from "../../openapi/portal/services/business.service";
import { AuthService } from "../auth/auth.service";

interface NavLink {
  logo: string;
  text: string;
  isActive?: boolean;
  sessionId: string;
}

interface NavGroup {
  name: string;
  icon?: string;
  links: NavLink[];
}


@UntilDestroy()
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  @Output() close$ = new EventEmitter();
  hasSessions = true;
  agents: Agent[] = [];
  groupedSessions?: GroupedSessionsResponse;
  groups: string[] = [];
  hasBusiness$ = this.businessStateService.hasBusiness$;
  activeAgentId = "";


  constructor(private router: Router,
              private authService: AuthService,
              private businessStateService: BusinessStateService,
              private agentsService: AgentsService,
              private sidenavService: SidenavService,
              private sessionsService: SessionsService) {
  }


  ngOnInit() {
    this.sidenavService.agentsUpdateRequired$.pipe(
      startWith(undefined),
      filter(() => this.authService.isLoggedIn()),
      switchMap(() => this.agentsService.getAgents()),
      untilDestroyed(this),
    ).subscribe((agents) => {
      this.agents = agents.agents.filter((agent) => agent.agent_id === "chat")
    });

    this.sidenavService.sessionsUpdateRequired$.pipe(
      startWith(undefined),
      filter(() => this.authService.isLoggedIn()),
      switchMap(() => this.sessionsService.getGroupedSessions()),
      untilDestroyed(this),
    ).subscribe((groupedSessions) => {
      this.groupedSessions = groupedSessions;
      this.groups = Object.keys(groupedSessions.time_to_sessions);
    });

    this.router.events.pipe(
      filter((event: any) => event instanceof RoutesRecognized),
      filter(() => this.authService.isLoggedIn()),
      map((event: RoutesRecognized) => event.state.root.firstChild?.params["agentId"]),
      untilDestroyed(this),
    ).subscribe((agentId) => this.activeAgentId = agentId);
  }

  triggerNavigation(agentId: string) {
    if (this.activeAgentId === agentId) {
      this.sidenavService.navigationTriggered$.next(undefined);
    }
  }
}
