<div class="page page--full playground">

  <app-playground-config
    (agent$)="initAgent($event.agent_id)"
    (update$)="agentUpdated()">
  </app-playground-config>

  <app-conversation
    *ngIf="agent"
    [style.max-height]="'100%'"
    [assistantNickname]="agent.assistant_nickname"
    [assistantAvatar]="agent.icon"
    [isLoading]="isLoading"
    [message]="message"
    [messages]="messages"
    [height]="'100%'"
    [fileUploadOn]="true"
    [resetRequired]="resetRequired"
    [replayEnabled]="true"
    [progress$]="progress$"
    (filesSelected$)="setFiles($event)"
    (reset$)="reset()"
    (replay$)="replay($event)"
    (sendMessage$)="sendMessage(message.value)">
  </app-conversation>
</div>
