import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from "@angular/forms";

@Pipe({
  name: 'isInvalid',
  pure: false
})
export class IsInvalidPipe implements PipeTransform {

  transform(form: FormGroup, field: string): boolean {
    return !!form.get(field)?.touched && !!form.get(field)?.invalid;
  }

}
