<div class="page">
<p><strong><span style="font-size:22pt;">Privacy Policy</span></strong></p>
<p><span style="font-size:13pt;">Last updated: January 16th, 2023</span></p>
<p><br></p>
<p><span style="font-size:13pt;">Welcome to Portal AI! We want to be transparent about how we handle your information. This Privacy Policy explains what Personal Data we collect, how we use it, and your rights regarding your data.</span></p>
<p><br></p>
<p><span style="font-size:16pt;">Who We Are:</span></p>
<p><span style="font-size:13pt;">We, Portal AI Inc., and our subsidiaries, are committed to protecting your privacy. Throughout this policy, you&apos;ll see terms like &quot;we,&quot; &quot;us,&quot; or &quot;our,&quot; referring to Portal AI.</span></p>
<p><br></p>
<p><span style="font-size:15pt;">What is Personal Data:</span></p>
<p><span style="font-size:13pt;">Personal Data includes any information about you that can identify you, as defined by data privacy laws.</span></p>
<p><br></p>
<p><span style="font-size:15pt;">Our Services:</span></p>
<p><span style="font-size:13pt;">We use artificial intelligence in our software services to help our Customers understand marketing, sales, pricing, and more products to optimize sales.</span></p>
<p><br></p>
<p><span style="font-size:16pt;">Sections of the Policy:</span></p>
<p><span style="font-size:13pt;">This policy is divided into three sections: Our Website, Our Services, and General. Each section covers different aspects of how we handle information.</span></p>
<p><br></p>
<p><span style="font-size:16pt;">Our Website:</span></p>
<p><span style="font-size:13.999999999999998pt;">1. Personal Data We Collect:</span></p>
<p><span style="font-size:13pt;">&nbsp; &nbsp;- Customer Account and Inquiries: When you interact with us, we may collect information like your name, email, and more. This helps us provide the best services.</span></p>
<p><span style="font-size:13pt;">&nbsp; &nbsp;- Social Media Pages: If you engage with our Social Media Pages, we may collect data as per the social media platform&apos;s policies.</span></p>
<p><span style="font-size:13pt;">&nbsp; &nbsp;- Information from Site Usage: We gather insights on how you use our website, like which pages you visit and when.</span></p>
<p><br></p>
<p><span style="font-size:13.999999999999998pt;">2. How We Use Information:</span></p>
<p><span style="font-size:13pt;">&nbsp; &nbsp;- We use your information for legitimate business interests, such as responding to inquiries, customizing insights, and improving our website.</span></p>
<p><span style="font-size:13pt;">&nbsp; &nbsp;- Marketing: With your consent, we may contact you about services we think you&apos;ll find interesting.</span></p>
<p><span style="font-size:13pt;">&nbsp; &nbsp;- Portal AI use and transfer to any other app of information received from Google APIs will 
    adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">
      Google API Services User Data Policy</a>, including the Limited Use requirements.</span></p>
<p><br></p>
<p><span style="font-size:13.999999999999998pt;">3. Sharing and Disclosure of Information:</span></p>
<p><span style="font-size:13pt;">&nbsp; &nbsp;- We only share your Personal Data as described in this policy or as disclosed to you. This includes sharing with service providers and in legal or business transfer scenarios.</span></p>
<p><br></p>
<p><span style="font-size:13.999999999999998pt;">4. Links to Other Websites:</span></p>
<p><span style="font-size:13pt;">&nbsp; &nbsp;- Our website may have links to Third Party Sites. Please check their privacy policies, as they govern the information you share with them.</span></p>
<p><br></p>
<p><span style="font-size:13.999999999999998pt;">5. Your Choices:</span></p>
<p><span style="font-size:13pt;">&nbsp; &nbsp;- You have the choice to provide certain Personal Data, and you can contact us to access, update, correct, or remove your information from our Site.</span></p>
<p><br></p>
<p><br></p>
<p><strong><span style="font-size:16pt;">Personal Data Processed on Behalf of Our Customers:</span></strong></p>
<p><span style="font-size:13pt;">- We process Personal Data for our Customers as a service provider, ensuring the functionality of our Services and addressing any technical issues.</span></p>
<p><span style="font-size:13pt;">- Customers manage Personal Data through our Services according to their own policies. If you&apos;re a job candidate, contact the Customer directly for data requests.</span></p>
<p><br></p>
<p><span style="font-size:13pt;">Personal Data Provided by Customers:</span></p>
<p><span style="font-size:13pt;">- Portal AI processes certain Personal Data provided by Customers&apos; employees or authorized users for the purpose of delivering our Services.</span></p>
<p><br></p>
<p><span style="font-size:13pt;">Information We Receive From Your Use of the Services:</span></p>
<p><span style="font-size:13pt;">- When you interact with our Services, we receive information about your usage patterns, login activities, and other similar data.</span></p>
<p><br></p>
<p><span style="font-size:13.999999999999998pt;">Personal Data We Control:</span></p>
<p><span style="font-size:13pt;">- Portal AI may control Personal Data of authorized users and Customers&apos; administrative personnel for the operation, support, and use of our Services.</span></p>
<p><br></p>
<p><span style="font-size:13.999999999999998pt;">How We Use Information:</span></p>
<p><span style="font-size:13pt;">We use Personal Data processed via the Services for:</span></p>
<p><span style="font-size:13pt;">- Providing Services to help Customers understand their audience and address issues with products, sales, and marketing campaigns.</span></p>
<p><span style="font-size:13pt;">- Legitimate business interests, such as optimizing and maintaining the Services, addressing inquiries, and improving user experience.</span></p>
<p><br></p>
<p><span style="font-size:13.999999999999998pt;">Sharing and Disclosure of Information:</span></p>
<p><span style="font-size:13pt;">- We do not share or disclose Personal Data processed in our Services, except for sharing aggregate data to generate insights.</span></p>
<p><span style="font-size:13pt;">- In certain situations, we may share Personal Data with service providers, in business transfers, or to comply with legal requirements.</span></p>
<p><br></p>
<p><strong><span style="font-size:16pt;">General:</span></strong></p>
<p><span style="font-size:13pt;">- Cookies and Other Technologies: We use cookies and similar technologies to gather information about Site and Services usage. You can control cookie use at the browser level.</span></p>
<p><br></p>
<p><span style="font-size:13pt;">- Security: We implement measures to protect Personal Data, but no transmission over the Internet is entirely secure.</span></p>
<p><br></p>
<p><span style="font-size:13pt;">- International Transfer: Personal Data may be transferred to the United States for the purposes described in this policy.</span></p>
<p><br></p>
<p><span style="font-size:13pt;">- Data Retention: We retain Personal Data for as long as necessary for the purposes described in this Privacy Policy or as required by law.</span></p>
<p><br></p>
<p><strong><span style="font-size:16pt;">Your Rights Relating to your Personal Data</span></strong></p>
<p><br></p>
<p><span style="font-size:13pt;">We respect your rights concerning your Personal Data, in accordance with local data protection laws. Depending on your location, including the EU, UK, or Switzerland, your rights may be under the EU General Data Protection Regulation (&ldquo;GDPR&rdquo;). If you are a California resident, please refer to the Additional Information for California Residents section for details on your rights.</span></p>
<p><br></p>
<p><span style="font-size:13.999999999999998pt;">To access, correct, update, or request deletion of your Personal Data:</span></p>
<p><span style="font-size:13pt;">- You can request access to your information and make corrections or deletions, considering the purpose of processing and applicable data protection laws.</span></p>
<p><br></p>
<p><span style="font-size:13.999999999999998pt;">To restrict processing of your Personal Data in certain circumstances:</span></p>
<p><span style="font-size:13pt;">- If you contest data accuracy or object to processing, we may restrict processing. If your objection is based on legitimate interests, we&apos;ll cease processing unless compelling grounds exist.</span></p>
<p><br></p>
<p><span style="font-size:13.999999999999998pt;">Opt-out of marketing communications:</span></p>
<p><span style="font-size:13pt;">- You can opt-out of marketing emails anytime by clicking &ldquo;unsubscribe&rdquo; or &ldquo;opt-out&rdquo; links. For other marketing forms, contact us through the &quot;Contact Us&quot; section.</span></p>
<p><br></p>
<p><span style="font-size:13.999999999999998pt;">Request portability of your Personal Data:</span></p>
<p><span style="font-size:13pt;">- You have the right to receive your data in a portable format if you provided it with consent or for a contract, and it&apos;s processed by automated means.</span></p>
<p><br></p>
<p><span style="font-size:13.999999999999998pt;">Freedom from decisions solely based on automated processing:</span></p>
<p><span style="font-size:13pt;">- We won&apos;t make decisions solely based on automated processing unless necessary for a contract or with your explicit consent.</span></p>
<p><br></p>
<p><span style="font-size:13.999999999999998pt;">Withdraw consent:</span></p>
<p><span style="font-size:13pt;">- If we rely on your consent for processing, you can withdraw it anytime. Withdrawal won&apos;t affect prior lawful processing.</span></p>
<p><br></p>
<p><span style="font-size:13.999999999999998pt;">Complaints to data protection authority:</span></p>
<p><span style="font-size:13pt;">- You have the right to complain to a data protection authority about our privacy practices. Contact your local authority for more information.</span></p>
<p><br></p>
<p><span style="font-size:13.999999999999998pt;">Contact Us section below to exercise your rights.</span></p>
<p><span style="font-size:13pt;">- For data processed on behalf of Customers, direct requests to the Portal AI Customer, as we, as a data processor, will refer requests to the relevant Customer.</span></p>
<p><br></p>
<p><span style="font-size:13.999999999999998pt;">Legal Bases for Processing:</span></p>
<p><span style="font-size:13pt;">- This Privacy Policy explains the legal bases for processing Personal Data. Contact us for specific legal basis details.</span></p>
<p><br></p>
<p><span style="font-size:13pt;">-&nbsp;</span><span style="font-size:13.999999999999998pt;">Legitimate Interests:</span><span style="font-size:13pt;">&nbsp;We process data for legitimate business interests, customer contract obligations, and providing the best services. We won&apos;t use your data where the impact on you overrides our interests, unless with consent or as required by law.</span></p>
<p><br></p>
<p><span style="font-size:18pt;">Additional Information for California Residents:</span></p>
<p><span style="font-size:13pt;">In compliance with the California Consumer Privacy Act (CCPA), this section outlines personal information collection, disclosure, and privacy rights for California residents.</span></p>
<p><br></p>
<p><span style="font-size:13.999999999999998pt;">Personal Information Collection and Disclosure:</span></p>
<p><span style="font-size:13pt;">- We disclose the categories of personal information collected in the past 12 months and the third parties with whom we shared it.</span></p>
<p><br></p>
<p><span style="font-size:13.999999999999998pt;">Categories of Personal Information Collected as a Business:</span></p>
<p><span style="font-size:13pt;">- Identifiers, Personal information from California Customer Records statute, Commercial information, Internet/electronic network activity, Geolocation data, Inferences drawn from collected information.</span></p>
<p><br></p>
<p><span style="font-size:13.999999999999998pt;">Sources of Personal Information:</span></p>
<p><span style="font-size:13pt;">- Direct collection, Indirect and technology-based collection, Collection via social media, Service providers, partners, or vendors.</span></p>
<p><br></p>
<p><span style="font-size:13.999999999999998pt;">Categories of Third Parties to Whom Disclosed:</span></p>
<p><span style="font-size:13pt;">- Customer contact service centers, Financial institutions and payment processors, IT and cloud/hosting service providers, Online analytics and marketing/advertising service providers, Data modeling or AI providers, Professional advisors, Platforms used for sales or marketing.</span></p>
<p><br></p>
<p><span style="font-size:13.999999999999998pt;">Your Rights Under CCPA:</span></p>
<p><span style="font-size:13pt;">- You have the right to know what personal information we collect, disclose, and sell.</span></p>
<p><span style="font-size:13pt;">- You have the right to request deletion of your personal information.</span></p>
<p><span style="font-size:13pt;">- You have the right to opt-out of the sale of your personal information.</span></p>
<p><span style="font-size:13pt;">- You have the right to non-discrimination for exercising your CCPA rights.</span></p>
<p><br></p>
<p><span style="font-size:13.999999999999998pt;">For More Information:</span></p>
<p><span style="font-size:13pt;">- Contact us through the &quot;Contact Us&quot; section for questions about CCPA or your privacy rights.</span></p>
<p><br></p>
<p><span style="font-size:15pt;">Purposes for the Collection and Use of Personal Information</span></p>
<p><br></p>
<p><span style="font-size:13pt;">We collect and use your personal information for various business or commercial purposes, as outlined in this privacy policy. These purposes include:</span></p>
<p><br></p>
<p><span style="font-size:13pt;">- Fulfilling or meeting the reason you provided the information and making lawful uses compatible with the context in which you provided it.</span></p>
<p><span style="font-size:13pt;">- Providing and optimizing your experience on our Site, presenting content effectively, and managing transactions.</span></p>
<p><span style="font-size:13pt;">- Fulfilling transactions, processing payments, and managing transaction processes.</span></p>
<p><span style="font-size:13pt;">- Communicating with you, responding to inquiries, and sending promotional or informational communications about our services.</span></p>
<p><span style="font-size:13pt;">- Engaging in analytics for marketing purposes and improving services, customer service, and customer experience.</span></p>
<p><span style="font-size:13pt;">- Understanding returns, product issues, and automating insights collection from users.</span></p>
<p><span style="font-size:13pt;">- Maintaining the safety, security, and integrity of our Site.</span></p>
<p><span style="font-size:13pt;">- Preventing and detecting fraud, hacking activities, security breaches, and other unlawful activities.</span></p>
<p><span style="font-size:13pt;">- Enforcing agreements and complying with legal or regulatory obligations.</span></p>
<p><span style="font-size:13pt;">- Evaluating or conducting mergers, divestitures, restructurings, or other sales or transfers of assets, where personal information is among the assets transferred.</span></p>
<p><span style="font-size:13pt;">- Performing other functions described at the time of information collection or to which you have consented.</span></p>
<p><br></p>
<p><span style="font-size:13pt;">We commit not to collect additional categories of personal information or use collected information for materially different, unrelated, or incompatible purposes without providing notice.</span></p>
<p><br></p>
<p><span style="font-size:18pt;">Sharing and Selling of Personal Information</span></p>
<p><br></p>
<p><span style="font-size:13pt;">Portal AI may share identifiers and internet activity information with marketing/advertising service companies for cross-context behavioral advertising.</span></p>
<p><br></p>
<p><span style="font-size:18pt;">Applicable Retention Period</span></p>
<p><br></p>
<p><span style="font-size:13pt;">The criteria for determining the retention period are outlined in the Data Retention section above.</span></p>
<p><br></p>
<p><span style="font-size:16pt;">Exercising Your Rights</span></p>
<p><br></p>
<p><span style="font-size:13pt;">California residents can contact Portal AI through the &quot;Contact Us&quot; section to exercise privacy rights.</span></p>
<p><br></p>
<p><span style="font-size:16pt;">Authorized Agents</span></p>
<p><br></p>
<p><span style="font-size:13pt;">You may use an authorized agent to submit requests to know or delete personal information held by Portal AI. We may require the agent to present signed written permission and verify your identity.</span></p>
<p><br></p>
<p><span style="font-size:16pt;">Other Information</span></p>
<p><br></p>
<p><span style="font-size:13pt;">- Minors: The Site and Services are not directed to children under 16. Portal AI does not knowingly collect Personal Data from children under 16.</span></p>
<p><br></p>
<p><span style="font-size:13pt;">- Online Tracking and Do-Not-Track Signals: The Site does not respond to Web browser &ldquo;Do Not Track&rdquo; signals at present. If this changes in the future, the Privacy Policy will be updated accordingly.</span></p>
<p><br></p>
<p><span style="font-size:13pt;">- Changes to the Privacy Policy: Changes may occur due to Site, Services, or business updates. We will post updated versions on the website, and your continued use implies consent to the revised Privacy Policy.</span></p>
<p><br></p>
<p><span style="font-size:13pt;">- Contact Us: For questions or exercising rights, contact us at support&#64;portal.ai or the provided mailing address.</span></p>
<p><br></p>
<p><span style="font-size:13pt;">Ending Note:</span></p>
<p><span style="font-size:13pt;">This summary covers the identified key points from the discussed texts, ensuring transparency in data processing, user rights, and contact information. For detailed information, refer to the respective privacy policies.</span></p>
<p><br></p>
<p><br></p>
</div>
