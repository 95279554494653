<div class="user">
  <div class="user__info">
    <a class="user__avatar" [routerLink]="['/', 'profile']">
      <img class="user__avatar" [src]="avatar$ | async">
    </a>
    <div class="user__actions">
      <div class="user__name">{{username$ | async}}</div>
      <div class="user__logout">
        <button class="button--primary button--sm"
                [class.button--loading]="isLoading"
                (click)="logout()">
          {{ isLoading ? 'Loading...' : 'Logout' }}
        </button>
        <a [routerLink]="['/', 'profile']"
           style="margin-left: 10px"
          class="button--primary button--sm"
          [class.button--loading]="isLoading">
          Profile
        </a>
      </div>
    </div>
  </div>

</div>
