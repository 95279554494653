import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SidenavService {
  sessionsUpdateRequired$ = new Subject<void>();
  agentsUpdateRequired$ = new Subject<void>();
  navigationTriggered$ = new BehaviorSubject<void>(undefined);

  constructor() { }
}
