/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { Agent } from '../models/agent';
import { AgentsResponse } from '../models/agents-response';
import { getAgent } from '../fn/agents/get-agent';
import { GetAgent$Params } from '../fn/agents/get-agent';
import { getAgents } from '../fn/agents/get-agents';
import { GetAgents$Params } from '../fn/agents/get-agents';

@Injectable({ providedIn: 'root' })
export class AgentsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAgents()` */
  static readonly GetAgentsPath = '/agents';

  /**
   * Get Agents.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAgents()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgents$Response(params?: GetAgents$Params, context?: HttpContext): Observable<StrictHttpResponse<AgentsResponse>> {
    return getAgents(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Agents.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAgents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgents(params?: GetAgents$Params, context?: HttpContext): Observable<AgentsResponse> {
    return this.getAgents$Response(params, context).pipe(
      map((r: StrictHttpResponse<AgentsResponse>): AgentsResponse => r.body)
    );
  }

  /** Path part for operation `getAgent()` */
  static readonly GetAgentPath = '/agents/{agent_id}';

  /**
   * Get Agent.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAgent()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgent$Response(params: GetAgent$Params, context?: HttpContext): Observable<StrictHttpResponse<Agent>> {
    return getAgent(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Agent.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAgent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgent(params: GetAgent$Params, context?: HttpContext): Observable<Agent> {
    return this.getAgent$Response(params, context).pipe(
      map((r: StrictHttpResponse<Agent>): Agent => r.body)
    );
  }

}
