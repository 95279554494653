<h3 class="h3">Business</h3>

<form class="form" (ngSubmit)="save()" *ngIf="businessForm && !isLoading" [formGroup]="businessForm">
  <div class="input__label">Title</div>
  <input formControlName="name" class="form__input input" placeholder="Title">
  <div class="input__error" *ngIf="businessForm | isInvalid: 'name'">The title is required</div>
  <div class="input__label">Description</div>
  <textarea formControlName="description" class="textarea" placeholder="Description"></textarea>
  <div class="input__error" *ngIf="businessForm | isInvalid: 'description'">The description is required for
    high-quality
    insights
  </div>
  <div class="actions">
    <button class="button--primary" style="margin-right: 20px" type="submit">Save</button>
    <button class="button--error" type="button" *ngIf="business" (click)="delete()">Delete Business</button>
  </div>
</form>
