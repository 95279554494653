/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { authenticateIntegration } from '../fn/integrations/authenticate-integration';
import { AuthenticateIntegration$Params } from '../fn/integrations/authenticate-integration';
import { AuthenticateIntegrationResponse } from '../models/authenticate-integration-response';
import { CreateIntegrationResponse } from '../models/create-integration-response';
import { deleteIntegration } from '../fn/integrations/delete-integration';
import { DeleteIntegration$Params } from '../fn/integrations/delete-integration';
import { DeleteIntegrationResponse } from '../models/delete-integration-response';
import { getIntegrations } from '../fn/integrations/get-integrations';
import { GetIntegrations$Params } from '../fn/integrations/get-integrations';
import { GetIntegrationsResponse } from '../models/get-integrations-response';
import { googleCallback } from '../fn/integrations/google-callback';
import { GoogleCallback$Params } from '../fn/integrations/google-callback';
import { initIntegration } from '../fn/integrations/init-integration';
import { InitIntegration$Params } from '../fn/integrations/init-integration';

@Injectable({ providedIn: 'root' })
export class IntegrationsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getIntegrations()` */
  static readonly GetIntegrationsPath = '/integrations';

  /**
   * Get Integrations.
   *
   * Get all integrations for the current user.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIntegrations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIntegrations$Response(params?: GetIntegrations$Params, context?: HttpContext): Observable<StrictHttpResponse<GetIntegrationsResponse>> {
    return getIntegrations(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Integrations.
   *
   * Get all integrations for the current user.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getIntegrations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIntegrations(params?: GetIntegrations$Params, context?: HttpContext): Observable<GetIntegrationsResponse> {
    return this.getIntegrations$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetIntegrationsResponse>): GetIntegrationsResponse => r.body)
    );
  }

  /** Path part for operation `initIntegration()` */
  static readonly InitIntegrationPath = '/integrations';

  /**
   * Init Integration.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `initIntegration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  initIntegration$Response(params: InitIntegration$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateIntegrationResponse>> {
    return initIntegration(this.http, this.rootUrl, params, context);
  }

  /**
   * Init Integration.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `initIntegration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  initIntegration(params: InitIntegration$Params, context?: HttpContext): Observable<CreateIntegrationResponse> {
    return this.initIntegration$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateIntegrationResponse>): CreateIntegrationResponse => r.body)
    );
  }

  /** Path part for operation `authenticateIntegration()` */
  static readonly AuthenticateIntegrationPath = '/integrations/{integration_id}/authenticate';

  /**
   * Authenticate Integration.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticateIntegration()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticateIntegration$Response(params: AuthenticateIntegration$Params, context?: HttpContext): Observable<StrictHttpResponse<AuthenticateIntegrationResponse>> {
    return authenticateIntegration(this.http, this.rootUrl, params, context);
  }

  /**
   * Authenticate Integration.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticateIntegration$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticateIntegration(params: AuthenticateIntegration$Params, context?: HttpContext): Observable<AuthenticateIntegrationResponse> {
    return this.authenticateIntegration$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuthenticateIntegrationResponse>): AuthenticateIntegrationResponse => r.body)
    );
  }

  /** Path part for operation `googleCallback()` */
  static readonly GoogleCallbackPath = '/integrations/google/callback';

  /**
   * Google Callback.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `googleCallback()` instead.
   *
   * This method doesn't expect any request body.
   */
  googleCallback$Response(params: GoogleCallback$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return googleCallback(this.http, this.rootUrl, params, context);
  }

  /**
   * Google Callback.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `googleCallback$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  googleCallback(params: GoogleCallback$Params, context?: HttpContext): Observable<void> {
    return this.googleCallback$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteIntegration()` */
  static readonly DeleteIntegrationPath = '/integrations/{integration_id}';

  /**
   * Delete Integration.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteIntegration()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteIntegration$Response(params: DeleteIntegration$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteIntegrationResponse>> {
    return deleteIntegration(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete Integration.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteIntegration$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteIntegration(params: DeleteIntegration$Params, context?: HttpContext): Observable<DeleteIntegrationResponse> {
    return this.deleteIntegration$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteIntegrationResponse>): DeleteIntegrationResponse => r.body)
    );
  }

}
