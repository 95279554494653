<mat-drawer-container class="drawer-container" autosize>
  <mat-drawer #sidenav mode="side" [opened]="true">
    <app-sidenav (close$)="sidenav.close()" *ngIf="(isLoggedIn$ | async) && (isSidenavRequired$ | async)"></app-sidenav>
  </mat-drawer>

  <label class="menu__btn" *ngIf="!sidenav.opened" (click)="sidenav.toggle()">
    <span></span>
    <span></span>
    <span></span>
  </label>

  <router-outlet></router-outlet>

</mat-drawer-container>


