import { Component } from '@angular/core';
import { RoutinesService } from "../../../openapi/portal/services/routines.service";
import { ActivatedRoute, Router } from "@angular/router";
import { RoutineWithSessions } from "../../../openapi/portal/models/routine-with-sessions";
import { SessionStatus } from "../../../openapi/portal/models";

@Component({
  selector: 'app-routine',
  templateUrl: './routine.component.html',
  styleUrl: './routine.component.scss'
})
export class RoutineComponent {
  routine?: RoutineWithSessions;
  agentId = '';
  routineId = '';
  newSessionRunning = false;
  readonly SessionStatus = SessionStatus;

  constructor(private routinesService: RoutinesService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.agentId = this.route.snapshot.paramMap.get("agentId") as string;
    this.routineId = this.route.snapshot.paramMap.get("routineId") as string;
    this.routinesService.getRoutine({routine_id: this.routineId}).subscribe((response) => {
      this.routine = response.routine;
    })
  }

  deleteRoutine(routine: RoutineWithSessions) {
    this.routinesService.deleteRoutine({routine_id: routine._id!}).subscribe((routines) => {
      this.router.navigate(['/', this.agentId]);
    })
  }

  runSession(routine: RoutineWithSessions) {
    this.newSessionRunning = true;
    this.routinesService.runAsyncRoutine({routine_id: routine._id!}).subscribe((routines) => {
      this.newSessionRunning = false;
      this.routine = routines.routines.find((r) => r._id === this.routineId);
    });
  }

}
