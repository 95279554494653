import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { AgentsService } from "../../../openapi/portal/services/agents.service";
import { Agent } from "../../../openapi/portal/models/agent";
import { tap } from "rxjs";
import { UntilDestroy } from "@ngneat/until-destroy";
import { PlaygroundService } from "../../../openapi/portal/services/playground.service";
import { PlaygroundAgent } from "../../../openapi/portal/models/playground-agent";
import { PlaygroundUpdateAgentsRequest } from "../../../openapi/portal/models/playground-update-agents-request";

@UntilDestroy()
@Component({
  selector: 'app-playground-config',
  templateUrl: './playground-config.component.html',
  styleUrl: './playground-config.component.scss'
})
export class PlaygroundConfigComponent implements OnInit {
  @Output() agent$ = new EventEmitter<Agent>();
  @Output() update$ = new EventEmitter<void>();
  agentForms: FormArray | undefined;
  agents: PlaygroundAgent[] = [];
  expandedInstructionsIndex: number | undefined = undefined;
  activePlaygroundAgent: PlaygroundAgent | undefined;
  isLoading = true;

  constructor(private fb: FormBuilder,
              private playgroundService: PlaygroundService,
              private agentsService: AgentsService) {
  }

  ngOnInit(): void {
    this.initAgents().subscribe();
  }

  updateAssistants() {
    this.isLoading = true;
    const body: PlaygroundUpdateAgentsRequest = {
      agents: this.agentForms?.value
    };
    this.playgroundService.updatePlaygroundAgents({body}).subscribe(() => {
      this.update$.emit();
      this.isLoading = false;
    });
  }

  getControls() {
    return this.agentForms ? this.agentForms.controls as UntypedFormGroup[] : [];
  }

  getControl(fg: UntypedFormGroup, name: string) {
    return fg.get(name) as UntypedFormControl;
  }

  activateAgent(agent: PlaygroundAgent) {
    this.activePlaygroundAgent = agent;
    this.agentsService.getAgent({agent_id: agent.id}).subscribe(
      agent => this.agent$.emit(agent)
    );
  }

  private initAgents() {
    return this.playgroundService.getPlaygroundAgents().pipe(
      tap(agents => {
        this.agents = agents.agents;
        this.agentForms = this.fb.array(this.agents.map(agent => this.fb.group(agent)));
        this.activateAgent(this.agents[0]);
        this.isLoading = false;
      }),
    );
  }

  protected readonly undefined = undefined;
}
