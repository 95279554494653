import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { environment } from "../environments/environment";
import { SessionComponent } from "./session/session.component";
import { ApiConfiguration } from "../openapi/portal/api-configuration";
import { BusinessComponent } from "./profile/business/business.component";
import { IsInvalidPipe } from "./utils/is-invalid.pipe";
import { RoutinesComponent } from "./routines/routines.component";
import { NewRoutineComponent } from "./routines/new-routine/new-routine.component";
import { FormatIntervalPipe } from "./routines/format-interval.pipe";
import { LoginComponent } from "./auth/login/login.component";
import { AuthComponent } from "./auth/auth/auth.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatSidenavModule } from "@angular/material/sidenav";
import { SidenavComponent } from "./sidenav/sidenav.component";
import { UserComponent } from "./sidenav/user/user.component";
import { AgentComponent } from "./agent/agent.component";
import { PrepareMessagePipe } from "./conversation/prepare-message.pipe";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MarkdownModule } from "ngx-markdown";
import { ConversationComponent } from "./conversation/conversation.component";
import { PlaygroundComponent } from "./playground/playground.component";
import { PlaygroundConfigComponent } from "./playground/playground-config/playground-config.component";
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';

import { Amplify } from 'aws-amplify';
import awsconfig from '../aws-exports';

import { AuthTokenInterceptor } from "./auth/auth-token-interceptor.service";
import { ForbiddenInterceptor } from "./auth/forbidden.interceptor";
import { NotFoundComponent } from "./not-found/not-found.component";
import {PrivacyPolicyComponent} from "./privacy-policy/privacy-policy.component";
import { ProfileComponent } from "./profile/profile/profile.component";
import { IntegrationsComponent } from "./profile/integrations/integrations.component";
import { AttachShopifyComponent } from "./profile/attach-shopify/attach-shopify.component";
import { AttachPgqlDbComponent } from "./profile/attach-pgql-db/attach-pgql-db.component";
import { AttachAmazonComponent } from "./profile/attach-amazon/attach-amazon.component";
import { AttachGoogleAdsComponent } from "./profile/attach-google-ads/attach-google-ads.component";
import { AboutUsComponent } from "./about-us/about-us.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { TermsOfServiceComponent } from "./terms-of-service/terms-of-service.component";
import { RoutineComponent } from "./routines/routine/routine.component";
import { AgentStatsComponent } from "./agent/agent-stats/agent-stats.component";
import {MatRadioModule} from "@angular/material/radio";
import {AmazonComponent} from "./amazon/amazon.component";

awsconfig['oauth'].redirectSignIn = `${window.location.origin}/`;
awsconfig['oauth'].redirectSignOut = `${window.location.origin}/`;
Amplify.configure(awsconfig);

@NgModule({
  declarations: [
    AppComponent,
    SessionComponent,
    BusinessComponent,
    RoutinesComponent,
    NewRoutineComponent,
    LoginComponent,
    AgentComponent,
    AuthComponent,
    PrepareMessagePipe,
    IsInvalidPipe,
    FormatIntervalPipe,
    SidenavComponent,
    UserComponent,
    ConversationComponent,
    PlaygroundComponent,
    PlaygroundConfigComponent,
    NotFoundComponent,
    PrivacyPolicyComponent,
    ProfileComponent,
    IntegrationsComponent,
    AttachShopifyComponent,
    AttachPgqlDbComponent,
    AttachAmazonComponent,
    AttachGoogleAdsComponent,
    AboutUsComponent,
    TermsOfServiceComponent,
    RoutineComponent,
    AgentStatsComponent,
    AmazonComponent
  ],
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatSidenavModule,
        MatTooltipModule,
        AmplifyAuthenticatorModule,
        MarkdownModule.forRoot(),
        MatCheckboxModule,
        MatRadioModule,
        // AmplifyAuthenticatorModule,
    ],
  providers: [
    {provide: ApiConfiguration, useValue: {rootUrl: environment.rootApiUrl}},
    {provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ForbiddenInterceptor, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
