<div class="page auth">
  <div class="auth__left">
    <div class="auth__logo-wrap">
      <h1 class="auth__title">Portal AI</h1>
      <img class="auth__logo" src="/assets/img/portal.svg" alt="logo">
    </div>
    <div class="auth__texts">
      <ng-container *ngFor="let text of texts; let i = index">
        <div *ngIf="i === currentIndex"
             class="auth__text"
             [@slideAnimation]="i === currentIndex ? 'active' : 'inactive'">
          <h3 class="auth__h3">{{ text.title }}</h3>
          <p class="auth__tip">{{ displayedText }}</p>
        </div>
      </ng-container>
    </div>
    <div></div>
  </div>
  <div class="auth__right">

    <div></div>
    <div *ngIf="step === 'init'">
      <h2 class="h2">Start Now</h2>
      <div class="auth__init">
        <button type="submit" class="form__button button--start" (click)="step = 'policy-checkboxes'">Login</button>
        <button type="submit" class="form__button button--start" (click)="step = 'beta'">Join Beta</button>
      </div>
    </div>

    <div *ngIf="step === 'policy-checkboxes'">
      <div class="auth__policies">
        <mat-checkbox [formControl]="policiesAccepted">
          I’ve read and accept the <a href="/terms-of-service" target="_blank">Terms of service</a>  and the <a href="/privacy-policy" target="_blank">Privacy Policy</a>
        </mat-checkbox>
        <button style="margin-top: 20px" type="submit"
                class="form__button button--start"
                (click)="step = 'login'" [class.button--loading]="!policiesAccepted.value">Continue</button>
      </div>
    </div>

    <!--    <a *ngIf="step === 'login'" href="/chat">-->
    <!--      <img src="/assets/img/login.png" alt="" height="460px">-->
    <!--    </a>-->

    <app-login *ngIf="step === 'login'"></app-login>

    <form *ngIf="step === 'beta'" class="form" [formGroup]="beta" (ngSubmit)="joinBeta()">
      <h2 class="h2">Join our closed Beta!</h2>
      <input type="email" formControlName="email" id="beta-email" name="email" placeholder="Your Email"
             class="form__input input input--full">
      <button type="submit" class="button--primary button--full">Join Beta</button>
    </form>

    <div *ngIf="step === 'beta-success'">
      <h1 class="h1">Success!</h1>
      <h3 class="h3">We will contact you soon</h3>
    </div>

    <div class="auth__footer">
      <div class="auth__links">
        <a routerLink="/privacy-policy" class="auth__link">Privacy Policy</a>
        <a routerLink="/" class="auth__link">About Us</a>
      </div>
      <div class="auth__address">
        PORTAL AI INC, address: 541 Jefferson Ave Ste 100 Redwood City, California San Mateo County, 94063, US
      </div>
    </div>
  </div>
</div>
