import { Component } from '@angular/core';
import { Agent } from "../../openapi/portal/models/agent";
import { FormControl } from "@angular/forms";
import { Message } from "../../openapi/portal/models/message";
import { Session } from "../../openapi/portal/models/session";
import { SessionsService } from "../../openapi/portal/services/sessions.service";
import { AgentsService } from "../../openapi/portal/services/agents.service";
import { MessageRole } from "../../openapi/portal/models/message-role";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable, Subscription, switchMap, tap } from "rxjs";
import { CreateSessionFromReplay$Params } from "../../openapi/portal/fn/sessions/create-session-from-replay";
import { Replay } from "../conversation/conversation.component";
import { SessionStatus } from "../../openapi/portal/models/session-status";
import { ConversationService } from "../conversation/conversation.service";
import { BodyCreateAsyncSession } from "../../openapi/portal/models/body-create-async-session";
import { BodyUpdateAsyncSession } from "../../openapi/portal/models/body-update-async-session";

@UntilDestroy()
@Component({
  selector: 'app-playground',
  templateUrl: './playground.component.html',
  styleUrl: './playground.component.scss'
})
export class PlaygroundComponent {
  agent!: Agent;
  isLoading = true;
  message = new FormControl('');
  messages: Message[] = [];
  selectedFiles: Blob[] | undefined;
  session?: Session;
  resetRequired = false;
  sessionUpdateSub = Subscription.EMPTY;
  sessionCreateSub = Subscription.EMPTY;
  progress$ = this.conversationService.progress$;

  constructor(private sessionsService: SessionsService,
              private conversationService: ConversationService,
              private agentService: AgentsService) {
  }

  ngOnInit() {
  }

  setFiles(files: Blob[]) {
    this.selectedFiles = files;
  }

  sendMessage(text: string | null) {
    text = text || '';
    if (!text && this.selectedFiles?.length === 0) {
      return;
    }

    if (this.isLoading) {
      return;
    }

    this.message.setValue("");
    this.isLoading = true;

    const message: Message = {
      text: text,
      role: MessageRole.User,
    }

    this.messages.push(message);

    if (this.session) {
      this.updateSession(text);
    } else {
      this.createSession(text);
    }
  }

  initAgent(agentId: string): void {
    this.isLoading = true;
    this.sessionCreateSub.unsubscribe();
    this.sessionUpdateSub.unsubscribe();
    this.agentService.getAgent({agent_id: agentId as string}).pipe(
      tap((agent) => this.agent = agent),
      tap(() => {
        this.reset();
        this.isLoading = false;
      })
    ).subscribe();
  }

  agentUpdated() {
    if (this.session) {
      this.resetRequired = true;
    }
  }

  reset() {
    this.session = undefined;
    this.messages = [{
      role: MessageRole.Assistant,
      text: this.agent.welcome_message,
    }];
    this.selectedFiles = undefined;
    this.message.setValue(this.agent.user_initial_message);
    this.resetRequired = false;
  }

  replay(replay: Replay) {
    const params: CreateSessionFromReplay$Params = {
      session_id: this.session?._id as string,
      last_message_index: replay.lastAssistantMessageIndex,
    }
    this.isLoading = true;
    this.message.setValue(replay.lastUserMessage);
    this.sessionCreateSub = this.sessionsService.createSessionFromReplay(params).pipe(
      tap((session) => this.setSession(session)),
      untilDestroyed(this),
    ).subscribe(() => {
      this.isLoading = false;
    }, (e) => this.handleError(e));
  }

  private createSession(prompt: string) {
    const body: BodyCreateAsyncSession = {
      text_prompt: prompt,
      agent_id: this.agent.agent_id,
      files: this.selectedFiles?.length ? Array.from(this.selectedFiles) : [],
    }
    this.sessionCreateSub = this.sessionsService.createAsyncSession({body}).pipe(
      switchMap((session) => this.waitSession(session.session_id)),
      untilDestroyed(this),
    ).subscribe(() => {
      this.isLoading = false;
      if (this.session?.status === SessionStatus.Error) {
        this.handleError({})
      }
    }, (e) => this.handleError(e));
  }

  private updateSession(prompt: string) {
    const body: BodyUpdateAsyncSession = {
      text_prompt: prompt,
      files: this.selectedFiles?.length ? Array.from(this.selectedFiles) : [],
    }
    this.sessionUpdateSub = this.sessionsService.updateAsyncSession({session_id: this.session?._id as string, body}).pipe(
      switchMap((response) => this.waitSession(response.session_id)),
      untilDestroyed(this),
    ).subscribe(() => {
      this.isLoading = false;
      if (this.session?.status === SessionStatus.Error) {
        this.handleError({})
      }
    }, (e) => this.handleError(e));
  }

  private handleError(e: any) {
    console.error(e);
    this.isLoading = false;
    this.messages.push({
      role: MessageRole.Assistant,
      text: "Sorry, we couldn't answer this. Refresh the page and try again.",
    })
  }

  private setSession(session: Session) {
    this.session = session;
    this.messages = session.messages;
  }

  private waitSession(sessionId: string): Observable<Session> {
    return this.conversationService.listenSession(sessionId).pipe(
      tap((session) => this.setSession(session)),
    );
  }

}
