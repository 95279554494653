import { Injectable } from '@angular/core';
import { CallbackUrls } from "../../openapi/portal/models/callback-urls";
import { IntegrationType } from "../../openapi/portal/models/integration-type";

@Injectable({
  providedIn: 'root'
})
export class AuthReturnUrlsService {

  constructor() { }

  getReturnUrls(provider: IntegrationType): CallbackUrls {
    const root = window.location.href + "?auth_provider=" + provider;
    return {
      success: root + '&auth_result=success',
      failure: root + '&auth_result=failure',
    };
  }
}
