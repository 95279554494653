<div class="page">
  <h1 class="h1">New Routine</h1>

  <form [formGroup]="newRoutine" *ngIf="!wasSaved" class="form" (ngSubmit)="save()">

    <div class="input__label">Name</div>
    <input formControlName="name" class="form__input input" placeholder="For example: Get revenue">
    <div class="input__error" *ngIf="newRoutine | isInvalid: 'name'">The name is required</div>

    <div class="input__label">Requirements</div>
    <textarea formControlName="requirements" class="textarea" placeholder="Requirements"></textarea>
    <div class="input__error" *ngIf="newRoutine | isInvalid: 'requirements'">The requirements is the good data parsing</div>

    <div class="input__label">SQL Query</div>
    <textarea formControlName="sql_query" class="textarea" placeholder="Sql Query"></textarea>
    <div class="input__error" *ngIf="newRoutine | isInvalid: 'sql_query'">The sql_query is required</div>

    <div class="input__label">Interval</div>
    <select class="select" formControlName="interval">
      <option *ngFor="let interval of intervals" [value]="interval.value">{{interval.label}}</option>
    </select>

    <button class="button--primary" [class.button--loading]="isLoading">Save</button>
  </form>

  <a class="button--primary" [routerLink]="['/', agentId, 'routines']" *ngIf="wasSaved">See All Routines</a>
</div>
