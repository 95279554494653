import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from "./auth.service";
import { map } from "rxjs/operators";
import { defaultAgentUrl } from "../app-routing.module";

@Injectable({
  providedIn: 'root'
})
export class NotAuthorizedOnlyGuard implements CanActivate {

  constructor(private authService: AuthService,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.authService.isLoggedIn$.pipe(
      map((isLoggedIn) => {
        if (isLoggedIn) {
          return this.router.parseUrl(defaultAgentUrl);
        } else {
          return true;
        }
      })
    );
  }

}
