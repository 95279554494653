import { Injectable } from '@angular/core';
import { Message } from "../../openapi/portal/models/message";

@Injectable({
  providedIn: 'root'
})
export class ExportMessageService {

  constructor() { }

  saveMessage(message: Message) {
    const filename = (message.agent?.name + "_" || '') + "response"
    if (this.isArray(message.text)) {
      this.save(filename, 'text/csv', this.toCsv(message.text))
    } else {
      this.save(filename, 'text/plain', message.text)
    }
  }

  private isArray(data: string) {
    try {
      const parsed = JSON.parse(data)
      return Array.isArray(parsed) && typeof parsed[0] === 'object'
    } catch (e) {
      return false
    }
  }

  private toCsv(data: string) {
    const parsed = JSON.parse(data)
    const csvRows = [];
    const headers = Object.keys(parsed[0]);
    csvRows.push(headers.join(','));
    for (const row of parsed) {
      const values = headers.map(header => {
        const escaped = ('' + row[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(','));
    }

    return csvRows.join('\n');
  }

  private save(filename: string, type: string, data: any) {
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.download = filename;
    anchor.href = url;
    anchor.click();
    window.URL.revokeObjectURL(url);
  }
}
