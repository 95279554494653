import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Business } from "../../../openapi/portal/models/business";
import { BusinessService } from "../../../openapi/portal/services/business.service";
import { Router } from "@angular/router";
import { BusinessStateService } from "./business-state.service";


@Component({
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrl: './business.component.scss'
})
export class BusinessComponent implements OnInit {
  businessForm!: FormGroup;
  business?: Business | null;
  isLoading = true;

  constructor(private fb: FormBuilder,
              private router: Router,
              private businessState: BusinessStateService,
              private businessService: BusinessService) {
  }

  ngOnInit() {
    this.businessService.getBusiness().subscribe((business) => {
      this.initForm(business.business);
      this.isLoading = false;
    });
  }

  save() {
    this.isLoading = true;
    const request = this.business ?
      this.businessService.updateBusiness({body: {...this.businessForm.value, ...{id: this.business._id!}}}) :
      this.businessService.createBusiness({body: this.businessForm.value});
    request.subscribe((business) => {
      this.initForm(business)
      this.isLoading = false;
    });
  }

  delete() {
    if (!this.business)
      return;
    this.isLoading = true;
    this.businessService.deleteBusiness({business_id: this.business._id!}).subscribe(() => {
      this.isLoading = false;
      this.businessState.setBusiness(null);
      this.router.navigate(['/']);
    });
  }

  private initForm(business: Business | null) {
    this.business = business;
    this.businessForm = this.fb.group({
      name: [business ? business.name : '', Validators.required],
      description: [business ? business.description : '', Validators.required],
    });
  }

}
