import { Component, OnInit } from '@angular/core';
import { AuthService } from "../auth.service";
import { Router } from "@angular/router";
import { filter, take } from "rxjs";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private authService: AuthService,
              private router: Router) {
  }

  ngOnInit() {
    this.authService.isLoggedIn$.pipe(
      take(1),
      filter((isLoggedIn) => isLoggedIn),
      untilDestroyed(this)
    ).subscribe((isLoggedIn) => {
      this.router.navigate(['/', 'chat']);
    })
  }
}
