/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { attachIntegration } from '../fn/internal-integrations/attach-integration';
import { AttachIntegration$Params } from '../fn/internal-integrations/attach-integration';
import { Integration } from '../models/integration';

@Injectable({ providedIn: 'root' })
export class InternalIntegrationsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `attachIntegration()` */
  static readonly AttachIntegrationPath = '/internal/integrations/shopify/{shop_name}/attach';

  /**
   * Attach Shopify Integration.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attachIntegration()` instead.
   *
   * This method doesn't expect any request body.
   */
  attachIntegration$Response(params: AttachIntegration$Params, context?: HttpContext): Observable<StrictHttpResponse<Integration>> {
    return attachIntegration(this.http, this.rootUrl, params, context);
  }

  /**
   * Attach Shopify Integration.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attachIntegration$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  attachIntegration(params: AttachIntegration$Params, context?: HttpContext): Observable<Integration> {
    return this.attachIntegration$Response(params, context).pipe(
      map((r: StrictHttpResponse<Integration>): Integration => r.body)
    );
  }

}
