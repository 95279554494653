<div class="about-us">
  <header class="navbar-area">
    <div class="container">
      <nav class="navbar">
        <a class="navbar-brand" routerLink="/auth">
          <!--?xml version="1.0" standalone="no"?-->

          <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="109.000000pt" height="109.000000pt"
               viewBox="0 0 109.000000 109.000000" preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,1.000000) scale(0.100000,-0.100000)">
              <path d="M1002 1025 c-49 -94 -45 -91 -85 -60 -136 103 -400 55 -599 -109
              -118 -98 -221 -262 -270 -431 -28 -98 -32 -304 -7 -369 l15 -38 33 66 c34 65
              44 76 56 56 4 -6 30 -22 58 -37 184 -94 504 26 677 254 65 85 133 224 161 328
              28 103 33 310 8 365 l-16 35 -31 -60z m-296 -254 c125 -76 45 -309 -139 -401
              -111 -56 -212 -37 -249 46 -17 39 -18 52 -9 95 24 109 97 199 204 250 74 35
              146 39 193 10z"></path>
            </g>
          </svg>
        </a>

        <div class="header-btn">
          <a routerLink="/auth" class="main-btn btn-hover">Try It Now</a>
        </div>
      </nav>
    </div>
  </header>
  <!-- ========================= header end ========================= -->

  <!-- ========================= hero-section start ========================= -->
  <section id="home" class="hero-section">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-xl-6 col-lg-6 col-md-10">
          <div class="hero-content">
            <h1>
              Let AI Boost Your Sales
            </h1>
            <p>
              Utilize AI-driven analysis of Amazon data to transform customer feedback into actionable insights, fostering a future of
              enhanced satisfaction and engagement.
            </p>

            <a routerLink="/auth" class="main-btn btn-hover">Start Now</a>
          </div>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-6">
          <div class="hero-image text-center text-lg-end">
            <img src="/assets/img/about-us/hero/hero-image.svg" alt="">
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ========================= hero-section end ========================= -->

  <!-- ========================= feature-section start ========================= -->
  <section id="features" class="feature-section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xxl-6 col-xl-7 col-lg-8 col-md-11">
          <div class="section-title text-center">
            <h2 class="mb-20">
              How It Works
            </h2>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- ========================= feature-section-1 start ========================= -->
  <section id="feature-1" class="feature-section-1 mt-60 pt-40">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 order-last order-lg-first">
          <div class="feature-image text-center text-lg-start">
            <img src="/assets/img/about-us/feature/feature-image-1.svg" alt="">
          </div>
        </div>
        <div class="col-lg-6 col-md-10">
          <div class="feature-content-wrapper">
            <div class="section-title">
              <h2 class="mb-20">Your Data Stays Yours</h2>
              <p class="mb-30">
                Connect Portal.ai to your Amazon account and rest assured: your data is processed individually.
                We analyze sales, customer demographics, and product performance exclusively for your business's benefit, maintaining strict privacy and security.
              </p>
              <a routerLink="/auth" class="main-btn btn-hover">Try It Now</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ========================= feature-section-1 end ========================= -->

  <!-- ========================= feature-section-2 start ========================= -->
  <section id="feature-2" class="feature-section-2 pt-40">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-10">
          <div class="feature-content-wrapper">
            <div class="section-title">
              <h2 class="mb-20">
                Get Personalized Actionable Recommendations
              </h2>
              <p class="mb-30">
                Portal.ai doesn’t just analyze data; it provides bespoke recommendations.
                Get individualized advice on inventory, pricing, and marketing, crafted solely for your business.
              </p>
              <a routerLink="/auth" class="main-btn btn-hover">Read More</a>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="feature-image text-lg-end">
            <img src="/assets/img/about-us/feature/feature-image-2.svg" alt="">
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ========================= feature-section-2 end ========================= -->

  <!-- ========================= feature-section-3 start ========================= -->
  <section id="feature-3" class="feature-section-1 mt-60 pt-40">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 order-last order-lg-first">
          <div class="feature-image text-center text-lg-start">
            <img src="/assets/img/about-us/feature/feature-image-1.svg" alt="">
          </div>
        </div>
        <div class="col-lg-6 col-md-10">
          <div class="feature-content-wrapper">
            <div class="section-title">
              <h2 class="mb-20">Dedicated to Your Growth</h2>
              <div class="mb-30">
                <p class="mb-20">
                  With continuous data analysis, Portal.ai evolves with your business.
                  Expect increasingly tailored insights and strategies, all while keeping your data private and never aggregated or shared.
                </p>
                <a routerLink="/auth" class="main-btn btn-hover">Try It Now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- ========================= footer start ========================= -->
  <footer class="footer pt-20">
    <div class="container">
      <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-10">
          <div class="footer-widget">
            <p class="desc">
              PORTAL AI INC, address: 541 Jefferson Ave Ste 100 Redwood City, California San Mateo County, 94063, US
            </p>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-10">
          <div class="footer-widget">
            <a routerLink="/privacy-policy" class="row">Privacy Policy</a>
            <a routerLink="/auth" class="row">Start Now</a>
          </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6">
        </div>
      </div>
    </div>
  </footer>
  <!-- ========================= footer end ========================= -->
</div>
