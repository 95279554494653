<div class="page page--full agent"
     [class.page--loading]="isLoading"
     *ngIf="agent">
  <div class="top">

    <div class="boxes" *ngIf="hasReport">
      <section class="box" *ngFor="let routine of report?.routine_reports">
        <div class="box__line box__line--title">{{routine.routine_name}}</div>
        <div class="box__line">{{routine.trend.short_recommendation}}</div>
        <div class="box__line">
             Status: <span class="trend__status trend__status--{{routine.trend.status}}">{{routine.trend.status}}</span>
        </div>
        <a [routerLink]="['/', agent.agent_id, 'routines', routine.routine_id]" class="button--primary button--sm">Details</a>
      </section>
    </div>

    <button class="button--primary button--sm" (click)="areStatsVisible = true" *ngIf="!areStatsVisible">Show Stats</button>
    <app-agent-stats *ngIf="areStatsVisible"></app-agent-stats>

    <div class="report__text" *ngIf="report?.text" [innerHTML]="report?.text"></div>
  </div>

  <app-conversation
    [style.max-height]="hasReport ? '60%' : '100%'"
    [assistantNickname]="agent.assistant_nickname"
    [assistantAvatar]="agent.icon"
    [isLoading]="isLoading"
    [message]="message"
    [messages]="messages"
    [height]="'100%'"
    [fileUploadOn]="true"
    [progress$]="progress$"
    (filesSelected$)="setFiles($event)"
    (sendMessage$)="sendMessage(message.value)">
  </app-conversation>
</div>
