import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { catchError, Observable, of, throwError } from 'rxjs';
import { Router } from "@angular/router";
import { notFoundPath } from "../app-routing.module";

@Injectable()
export class ForbiddenInterceptor implements HttpInterceptor {

  constructor(private router: Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 403) {
          this.router.navigate([notFoundPath]);
          return of(err.message);
        } {
          return throwError(err);
        }
      })
    )
  }
}
