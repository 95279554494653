import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../auth/auth.service";

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  username$ = this.authService.username$;
  avatar$ = this.authService.avatar$;
  isLoading = false;


  constructor(private authService: AuthService) {
  }

  ngOnInit() {

  }

  logout() {
    this.isLoading = true;
    this.authService.logout();
  }
}
