<div class="page">

  <h1>Portal AI Terms of Service</h1>

  <p><strong>Last Updated:</strong> January 16th, 2024</p>

  <p>Portal AI Inc., representing itself and its subsidiaries ("Portal AI," "we," "us," or "our"), is enthusiastic about
    contributing to the growth of your business through AI-powered insights. Our services target individuals aged 18 or
    older, English speakers, and those managing a business. By accessing or using our services, you agree to comply with
    the following terms.</p>

  <h2>1. General Terms and Conditions for All Users</h2>

  <h3>Changes to the Terms of Use:</h3>
  <p>We reserve the right to update these Terms of Use at our discretion. Your continued use of the Website implies
    acceptance of changes. Regularly check for updates.</p>

  <h3>Accessing the Website and Account Security:</h3>
  <p>We may modify Dashboards and insights without notice. You agree to provide accurate registration details. Your
    account is personal, and security information must be kept confidential. Notify us promptly of any unauthorized
    access.</p>

  <h3>Intellectual Property Rights:</h3>
  <p>The Website and its contents are owned by Portal AI and protected by intellectual property laws. User Contributions
    are owned by contributors. By submitting, you grant Portal AI rights outlined in our Privacy Policy.</p>

  <h2>2. Trademarks</h2>
  <p>Portal AI's name, logos, and related marks are trademarks. Unauthorized use is prohibited. Other trademarks on the
    Website belong to their respective owners.</p>

  <h2>3. Prohibited Uses</h2>
  <p>The Website is for lawful purposes. Prohibited uses include violating laws, impersonation, creating false
    identities, and unauthorized access. Users agree not to interfere with the Website's proper functioning.</p>

  <h2>4. Monitoring and Enforcement</h2>
  <p>We may remove User Contributions, disclose user information, and take legal action for violations. Users must
    adhere to Content Standards. We cooperate with law enforcement.</p>

  <h2>5. Disclaimer of Warranties</h2>
  <p>Use of the Website is at your own risk. Portal AI disclaims warranties regarding completeness, reliability,
    quality, accuracy, and availability. We do not guarantee specific outcomes.</p>

  <h2>6. Limitation on Liability</h2>
  <p>Portal AI is not liable for indirect, special, or incidental damages. Liability is limited to the greater of $1,000
    or the amount paid for the service. This limitation applies to all claims, whether in contract, tort, or
    otherwise.</p>

  <h2>7. Indemnification</h2>
  <p>Users agree to defend and indemnify Portal AI from claims arising from violations of these Terms of Use or Website
    use.</p>

  <h2>8. Governing Law and Jurisdiction</h2>
  <p>California law governs these Terms of Use. Legal actions will be in federal or state courts in Santa Clara County,
    California.</p>

  <h2>9. Term and Termination</h2>
  <p>Either party may terminate the Agreement with notice. Paid services are subject to commercial agreement terms.</p>

  <h2>10. Waiver and Severability</h2>
  <p>No waiver is considered ongoing. If a provision is invalid, the remaining terms remain in force.</p>

  <h2>11. Entire Agreement</h2>
  <p>These Terms of Use constitute the sole agreement and supersede prior agreements.</p>

  <h2>12. Your Comments and Concerns</h2>
  <p>Operated by Portal AI Inc., contact us at <a href="mailto:support@portal.ai">support&#64;portal.ai</a>.
    Privacy-related notices follow the Privacy Policy. Other communications should be directed accordingly.</p>

  <h3>Ending Note:</h3>
  <p>This summary provides an overview of the key elements in the Portal AI Terms of Service, emphasizing user
    responsibilities, intellectual property, limitations of liability, and governing law. For detailed information,
    refer to the full Terms of Service document.</p>
</div>
