import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./bootstrap.scss', './about-us.component.scss']
})
export class AboutUsComponent {

}
