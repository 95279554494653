/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createRoutine } from '../fn/routines/create-routine';
import { CreateRoutine$Params } from '../fn/routines/create-routine';
import { deleteRoutine } from '../fn/routines/delete-routine';
import { DeleteRoutine$Params } from '../fn/routines/delete-routine';
import { getRoutine } from '../fn/routines/get-routine';
import { GetRoutine$Params } from '../fn/routines/get-routine';
import { getRoutines } from '../fn/routines/get-routines';
import { GetRoutines$Params } from '../fn/routines/get-routines';
import { Routine } from '../models/routine';
import { RoutineResponse } from '../models/routine-response';
import { RoutinesResponse } from '../models/routines-response';
import { runAsyncRoutine } from '../fn/routines/run-async-routine';
import { RunAsyncRoutine$Params } from '../fn/routines/run-async-routine';
import { runRoutine } from '../fn/routines/run-routine';
import { RunRoutine$Params } from '../fn/routines/run-routine';

@Injectable({ providedIn: 'root' })
export class RoutinesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getRoutines()` */
  static readonly GetRoutinesPath = '/routines';

  /**
   * Get Routines.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRoutines()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoutines$Response(params: GetRoutines$Params, context?: HttpContext): Observable<StrictHttpResponse<RoutinesResponse>> {
    return getRoutines(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Routines.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRoutines$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoutines(params: GetRoutines$Params, context?: HttpContext): Observable<RoutinesResponse> {
    return this.getRoutines$Response(params, context).pipe(
      map((r: StrictHttpResponse<RoutinesResponse>): RoutinesResponse => r.body)
    );
  }

  /** Path part for operation `createRoutine()` */
  static readonly CreateRoutinePath = '/routines';

  /**
   * Create Routine.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createRoutine()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createRoutine$Response(params: CreateRoutine$Params, context?: HttpContext): Observable<StrictHttpResponse<Routine>> {
    return createRoutine(this.http, this.rootUrl, params, context);
  }

  /**
   * Create Routine.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createRoutine$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createRoutine(params: CreateRoutine$Params, context?: HttpContext): Observable<Routine> {
    return this.createRoutine$Response(params, context).pipe(
      map((r: StrictHttpResponse<Routine>): Routine => r.body)
    );
  }

  /** Path part for operation `getRoutine()` */
  static readonly GetRoutinePath = '/routines/{routine_id}';

  /**
   * Get Routine.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRoutine()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoutine$Response(params: GetRoutine$Params, context?: HttpContext): Observable<StrictHttpResponse<RoutineResponse>> {
    return getRoutine(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Routine.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRoutine$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRoutine(params: GetRoutine$Params, context?: HttpContext): Observable<RoutineResponse> {
    return this.getRoutine$Response(params, context).pipe(
      map((r: StrictHttpResponse<RoutineResponse>): RoutineResponse => r.body)
    );
  }

  /** Path part for operation `deleteRoutine()` */
  static readonly DeleteRoutinePath = '/routines/{routine_id}';

  /**
   * Delete Routine.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRoutine()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRoutine$Response(params: DeleteRoutine$Params, context?: HttpContext): Observable<StrictHttpResponse<RoutinesResponse>> {
    return deleteRoutine(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete Routine.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteRoutine$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRoutine(params: DeleteRoutine$Params, context?: HttpContext): Observable<RoutinesResponse> {
    return this.deleteRoutine$Response(params, context).pipe(
      map((r: StrictHttpResponse<RoutinesResponse>): RoutinesResponse => r.body)
    );
  }

  /** Path part for operation `runRoutine()` */
  static readonly RunRoutinePath = '/routines/{routine_id}/run';

  /**
   * Run Routine.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `runRoutine()` instead.
   *
   * This method doesn't expect any request body.
   */
  runRoutine$Response(params: RunRoutine$Params, context?: HttpContext): Observable<StrictHttpResponse<RoutinesResponse>> {
    return runRoutine(this.http, this.rootUrl, params, context);
  }

  /**
   * Run Routine.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `runRoutine$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  runRoutine(params: RunRoutine$Params, context?: HttpContext): Observable<RoutinesResponse> {
    return this.runRoutine$Response(params, context).pipe(
      map((r: StrictHttpResponse<RoutinesResponse>): RoutinesResponse => r.body)
    );
  }

  /** Path part for operation `runAsyncRoutine()` */
  static readonly RunAsyncRoutinePath = '/routines/{routine_id}/run/async';

  /**
   * Run Async Routine.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `runAsyncRoutine()` instead.
   *
   * This method doesn't expect any request body.
   */
  runAsyncRoutine$Response(params: RunAsyncRoutine$Params, context?: HttpContext): Observable<StrictHttpResponse<RoutinesResponse>> {
    return runAsyncRoutine(this.http, this.rootUrl, params, context);
  }

  /**
   * Run Async Routine.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `runAsyncRoutine$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  runAsyncRoutine(params: RunAsyncRoutine$Params, context?: HttpContext): Observable<RoutinesResponse> {
    return this.runAsyncRoutine$Response(params, context).pipe(
      map((r: StrictHttpResponse<RoutinesResponse>): RoutinesResponse => r.body)
    );
  }

}
