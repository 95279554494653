import { CanActivateFn, Router } from '@angular/router';
import { inject } from "@angular/core";
import { tap } from "rxjs";
import { map } from "rxjs/operators";
import { BusinessStateService } from "./business-state.service";

export const hasBusinessGuard: CanActivateFn = (route, state) => {
  const service = inject(BusinessStateService)
  const router = inject(Router)
  return service.business$.pipe(
    map((business) => business !== null),
    tap((hasBusiness) => {
      return hasBusiness ? true : router.navigate(['/chat'])
    })
  );
};
