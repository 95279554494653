<div class="config" [class.config--loading]="isLoading">

  <div class="agents">
    <div class="agent" *ngFor="let agentForm of getControls(); let i = index">
      <div class="form"
           *ngIf="expandedInstructionsIndex === i || expandedInstructionsIndex === undefined"
           [class.form--full-height]="expandedInstructionsIndex === i"
           [formGroup]="agentForm">
        <div class="agent__label" [class.agent__label--big]="expandedInstructionsIndex === i">
          <div class="agent__title" (click)="activateAgent(agentForm.value)">
            <img class="agent__icon" [src]="getControl(agentForm, 'icon').value" alt="">
            <span class="agent__name"
                  [class.a--underlined]="activePlaygroundAgent?.id !== getControl(agentForm, 'id').value">
            {{getControl(agentForm, 'name').value}}
          </span>
          </div>
          <span class="a a--underlined"
                *ngIf="expandedInstructionsIndex === i"
                (click)="expandedInstructionsIndex = undefined">
          Hide
        </span>
          <span class="a a--underlined"
                *ngIf="expandedInstructionsIndex !== i"
                (click)="expandedInstructionsIndex = i">
          Expand Instructions
        </span>
        </div>
        <textarea class="textarea textarea--full"
                  [class.textarea--full-height]="expandedInstructionsIndex === i"
                  formControlName="instructions"
                  placeholder="Instructions"></textarea>
      </div>
    </div>
  </div>

  <button class="button--start button--full"
          [class.button--loading]="isLoading"
          (click)="updateAssistants()">Update
  </button>
</div>
