<div class="integration" [class.integration--loading]="isLoading">
  <h4 class="h4">PostgresQL DB</h4>

  <div *ngIf="integration; else connect">
    <p class="integration__line" *ngIf="integration.name">DB: {{ integration.name }}</p>
    <button class="button button--sm button--error" (click)="detach$.emit(integration)">Disconnect</button>
  </div>


  <ng-template #connect>
    <p class="integration__description">
      Link your custom PostgreSQL database to analyze your operational data in real-time.
      Uncover trends, optimize resources, and make informed decisions to propel your business forward with tailored insights.
    </p>

    <button class="button button--postgres button--has-icon"
            [class.button--loading]="isLoading"
            (click)="attach()">
      <img class="button__icon" src="/assets/img/postgresql.webp" alt="">
      Connect PostgresQL DB
    </button>
  </ng-template>
</div>
