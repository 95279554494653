<div class="page">
  <h1 class="h1">Profile</h1>

  <section class="section">
    <app-business></app-business>
  </section>
  <section class="section">
    <app-integrations></app-integrations>
  </section>
</div>
