/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AsyncSessionResponse } from '../models/async-session-response';
import { createAsyncSession } from '../fn/sessions/create-async-session';
import { CreateAsyncSession$Params } from '../fn/sessions/create-async-session';
import { createSession } from '../fn/sessions/create-session';
import { CreateSession$Params } from '../fn/sessions/create-session';
import { createSessionFromReplay } from '../fn/sessions/create-session-from-replay';
import { CreateSessionFromReplay$Params } from '../fn/sessions/create-session-from-replay';
import { getGroupedSessions } from '../fn/sessions/get-grouped-sessions';
import { GetGroupedSessions$Params } from '../fn/sessions/get-grouped-sessions';
import { getSession } from '../fn/sessions/get-session';
import { GetSession$Params } from '../fn/sessions/get-session';
import { getSessions } from '../fn/sessions/get-sessions';
import { GetSessions$Params } from '../fn/sessions/get-sessions';
import { GetSessionsResponse } from '../models/get-sessions-response';
import { GroupedSessionsResponse } from '../models/grouped-sessions-response';
import { Session } from '../models/session';
import { subscribeToSession } from '../fn/sessions/subscribe-to-session';
import { SubscribeToSession$Params } from '../fn/sessions/subscribe-to-session';
import { updateAsyncSession } from '../fn/sessions/update-async-session';
import { UpdateAsyncSession$Params } from '../fn/sessions/update-async-session';
import { updateSession } from '../fn/sessions/update-session';
import { UpdateSession$Params } from '../fn/sessions/update-session';

@Injectable({ providedIn: 'root' })
export class SessionsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getSessions()` */
  static readonly GetSessionsPath = '/sessions';

  /**
   * Get Sessions.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSessions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSessions$Response(params: GetSessions$Params, context?: HttpContext): Observable<StrictHttpResponse<GetSessionsResponse>> {
    return getSessions(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Sessions.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSessions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSessions(params: GetSessions$Params, context?: HttpContext): Observable<GetSessionsResponse> {
    return this.getSessions$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetSessionsResponse>): GetSessionsResponse => r.body)
    );
  }

  /** Path part for operation `createSession()` */
  static readonly CreateSessionPath = '/sessions';

  /**
   * Create Session.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createSession()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createSession$Response(params: CreateSession$Params, context?: HttpContext): Observable<StrictHttpResponse<Session>> {
    return createSession(this.http, this.rootUrl, params, context);
  }

  /**
   * Create Session.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createSession$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createSession(params: CreateSession$Params, context?: HttpContext): Observable<Session> {
    return this.createSession$Response(params, context).pipe(
      map((r: StrictHttpResponse<Session>): Session => r.body)
    );
  }

  /** Path part for operation `createAsyncSession()` */
  static readonly CreateAsyncSessionPath = '/sessions/async';

  /**
   * Create Async Session.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAsyncSession()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createAsyncSession$Response(params: CreateAsyncSession$Params, context?: HttpContext): Observable<StrictHttpResponse<AsyncSessionResponse>> {
    return createAsyncSession(this.http, this.rootUrl, params, context);
  }

  /**
   * Create Async Session.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createAsyncSession$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createAsyncSession(params: CreateAsyncSession$Params, context?: HttpContext): Observable<AsyncSessionResponse> {
    return this.createAsyncSession$Response(params, context).pipe(
      map((r: StrictHttpResponse<AsyncSessionResponse>): AsyncSessionResponse => r.body)
    );
  }

  /** Path part for operation `createSessionFromReplay()` */
  static readonly CreateSessionFromReplayPath = '/sessions/replay';

  /**
   * Create Session From Replay.
   *
   * Create a new session from a previous session. Used by playground.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createSessionFromReplay()` instead.
   *
   * This method doesn't expect any request body.
   */
  createSessionFromReplay$Response(params: CreateSessionFromReplay$Params, context?: HttpContext): Observable<StrictHttpResponse<Session>> {
    return createSessionFromReplay(this.http, this.rootUrl, params, context);
  }

  /**
   * Create Session From Replay.
   *
   * Create a new session from a previous session. Used by playground.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createSessionFromReplay$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createSessionFromReplay(params: CreateSessionFromReplay$Params, context?: HttpContext): Observable<Session> {
    return this.createSessionFromReplay$Response(params, context).pipe(
      map((r: StrictHttpResponse<Session>): Session => r.body)
    );
  }

  /** Path part for operation `getGroupedSessions()` */
  static readonly GetGroupedSessionsPath = '/sessions/grouped';

  /**
   * Get Grouped Sessions.
   *
   * Get all sessions belongs to a user, grouped by date.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGroupedSessions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGroupedSessions$Response(params?: GetGroupedSessions$Params, context?: HttpContext): Observable<StrictHttpResponse<GroupedSessionsResponse>> {
    return getGroupedSessions(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Grouped Sessions.
   *
   * Get all sessions belongs to a user, grouped by date.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGroupedSessions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGroupedSessions(params?: GetGroupedSessions$Params, context?: HttpContext): Observable<GroupedSessionsResponse> {
    return this.getGroupedSessions$Response(params, context).pipe(
      map((r: StrictHttpResponse<GroupedSessionsResponse>): GroupedSessionsResponse => r.body)
    );
  }

  /** Path part for operation `getSession()` */
  static readonly GetSessionPath = '/sessions/{session_id}';

  /**
   * Get Session.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSession()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSession$Response(params: GetSession$Params, context?: HttpContext): Observable<StrictHttpResponse<Session>> {
    return getSession(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Session.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSession$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSession(params: GetSession$Params, context?: HttpContext): Observable<Session> {
    return this.getSession$Response(params, context).pipe(
      map((r: StrictHttpResponse<Session>): Session => r.body)
    );
  }

  /** Path part for operation `updateSession()` */
  static readonly UpdateSessionPath = '/sessions/{session_id}';

  /**
   * Update Session.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSession()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  updateSession$Response(params: UpdateSession$Params, context?: HttpContext): Observable<StrictHttpResponse<Session>> {
    return updateSession(this.http, this.rootUrl, params, context);
  }

  /**
   * Update Session.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateSession$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  updateSession(params: UpdateSession$Params, context?: HttpContext): Observable<Session> {
    return this.updateSession$Response(params, context).pipe(
      map((r: StrictHttpResponse<Session>): Session => r.body)
    );
  }

  /** Path part for operation `updateAsyncSession()` */
  static readonly UpdateAsyncSessionPath = '/sessions/{session_id}/async';

  /**
   * Update Async Session.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAsyncSession()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  updateAsyncSession$Response(params: UpdateAsyncSession$Params, context?: HttpContext): Observable<StrictHttpResponse<AsyncSessionResponse>> {
    return updateAsyncSession(this.http, this.rootUrl, params, context);
  }

  /**
   * Update Async Session.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAsyncSession$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  updateAsyncSession(params: UpdateAsyncSession$Params, context?: HttpContext): Observable<AsyncSessionResponse> {
    return this.updateAsyncSession$Response(params, context).pipe(
      map((r: StrictHttpResponse<AsyncSessionResponse>): AsyncSessionResponse => r.body)
    );
  }

  /** Path part for operation `subscribeToSession()` */
  static readonly SubscribeToSessionPath = '/sessions/{session_id}/subscribe';

  /**
   * Subscribe To Session.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscribeToSession()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscribeToSession$Response(params: SubscribeToSession$Params, context?: HttpContext): Observable<StrictHttpResponse<Session>> {
    return subscribeToSession(this.http, this.rootUrl, params, context);
  }

  /**
   * Subscribe To Session.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscribeToSession$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscribeToSession(params: SubscribeToSession$Params, context?: HttpContext): Observable<Session> {
    return this.subscribeToSession$Response(params, context).pipe(
      map((r: StrictHttpResponse<Session>): Session => r.body)
    );
  }

}
