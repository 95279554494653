import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { catchError, combineLatest, distinctUntilChanged, Observable, of, switchMap, tap } from "rxjs";
import { filter, map } from "rxjs/operators";
import { AgentsService } from "../../openapi/portal/services/agents.service";
import { Agent } from "../../openapi/portal/models/agent";
import { Report } from "../../openapi/portal/models/report";
import { FormControl } from "@angular/forms";
import { MessageRole } from "../../openapi/portal/models/message-role";
import { Message } from "../../openapi/portal/models/message";
import { SessionsService } from "../../openapi/portal/services/sessions.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ReportsService } from "../../openapi/portal/services/reports.service";
import { Session } from "../../openapi/portal/models/session";
import { SidenavService } from "../sidenav/sidenav.service";
import { BusinessStateService } from "../profile/business/business-state.service";
import { ConversationService } from "../conversation/conversation.service";
import { BodyUpdateAsyncSession } from "../../openapi/portal/models/body-update-async-session";
import { BodyCreateAsyncSession } from "../../openapi/portal/models/body-create-async-session";
import { notFoundPath } from "../app-routing.module";
import { AuthService } from "../auth/auth.service";

@UntilDestroy()
@Component({
  selector: 'app-agent',
  templateUrl: './agent.component.html',
  styleUrl: './agent.component.scss'
})
export class AgentComponent implements OnInit {
  areStatsVisible = false;
  agent!: Agent;
  isLoading = true;
  message = new FormControl('');
  messages: Message[] = [];
  selectedFiles: Blob[] | undefined;
  report?: Report;
  hasReport = false;
  session?: Session;
  progress$ = this.conversationService.progress$;
  agentIdParam$ = this.route.paramMap.pipe(
    map(params => params.get('agentId')),
    distinctUntilChanged((prev, curr) => prev === curr),
  );

  constructor(private route: ActivatedRoute,
              private router: Router,
              private authService: AuthService,
              private sidenavService: SidenavService,
              private reportsService: ReportsService,
              private conversationService: ConversationService,
              private sessionsService: SessionsService,
              private sessionService: SessionsService,
              private businessStateService: BusinessStateService,
              private agentService: AgentsService) {
  }

  ngOnInit() {
    combineLatest([this.sidenavService.navigationTriggered$, this.agentIdParam$]).pipe(
      tap(() => this.isLoading = true),
      tap(([, agentId]) => console.log(agentId)),
      switchMap(([, agentId]) => this.initAgent(agentId as string)),
      catchError(() => this.router.navigate([notFoundPath])),
      filter(() => !!this.agent),
      switchMap(() => this.initReport(this.agent.agent_id)),
      untilDestroyed(this),
    ).subscribe(() => {
      this.isLoading = false;
    });
  }

  setFiles(files: Blob[]) {
    this.selectedFiles = files;
  }

  sendMessage(text: string | null) {
    if (!text || this.isLoading) {
      return;
    }

    this.message.setValue("");
    this.isLoading = true;

    const message: Message = {
      text: text,
      role: MessageRole.User,
    }

    this.messages.push(message);

    if (this.session) {
      this.updateSession(text);
    } else {
      this.createSession(text);
    }
  }

  private createSession(prompt: string) {
    const body: BodyCreateAsyncSession = {
      text_prompt: prompt,
      agent_id: this.agent.agent_id,
      files: this.selectedFiles?.length ? Array.from(this.selectedFiles) : [],
    }
    this.sessionsService.createAsyncSession({body}).pipe(
      switchMap((session) => this.waitSession(session.session_id)),
      switchMap((session) => this.processUpdate()),
      untilDestroyed(this),
    ).subscribe(() => {
      this.isLoading = false;
    }, (e) => {
      this.isLoading = false;
      const msg = e.error.detail || "Sorry, we couldn't answer this. Refresh the page and try again.";
      this.messages.push({
        role: MessageRole.Assistant,
        text: msg,
      })
    });
  }

  private updateSession(prompt: string) {
    const body: BodyUpdateAsyncSession = {
      text_prompt: prompt,
      files: this.selectedFiles?.length ? Array.from(this.selectedFiles) : [],
    }
    this.sessionService.updateAsyncSession({session_id: this.session?._id as string, body}).pipe(
      switchMap((session) => this.waitSession(session.session_id)),
      switchMap((session) => this.processUpdate()),
      untilDestroyed(this),
    ).subscribe(() => {
      this.isLoading = false;
    }, (e) => {
      this.isLoading = false;
      const msg = e.error.detail || "Sorry, we couldn't answer this. Refresh the page and try again.";
      this.messages.push({
        role: MessageRole.Assistant,
        text: msg,
      })
    });
  }

  private setSession(session: Session) {
    this.session = session;
    this.messages = session.messages;
  }

  private initAgent(agentId: string): Observable<Agent> {
    return this.agentService.getAgent({agent_id: agentId as string}).pipe(
      tap((agent) => this.agent = agent),
      tap((agent) => {
        this.messages = [{
          role: MessageRole.Assistant,
          text: agent.welcome_message,
        }];
        this.session = undefined;
        this.message.setValue(agent.user_initial_message);
      })
    );
  }

  private processUpdate() {
    const requests: Array<Observable<any>> = [];
    this.sidenavService.agentsUpdateRequired$.next();
    requests.push(this.businessStateService.updateBusiness());
    requests.push(this.initReport(this.agent.agent_id));
    this.sidenavService.sessionsUpdateRequired$.next();
    return combineLatest(requests)
  }

  private initReport(agentId: string) {
    return this.reportsService.getReport({agent_id: agentId}).pipe(
      catchError(() => of({} as Report)),
      tap(report => {
        this.report = report;
        this.hasReport = !!report.routine_reports?.length
      }),
    );
  }

  private waitSession(sessionId: string): Observable<Session> {
    return this.conversationService.listenSession(sessionId).pipe(
      tap((session) => this.setSession(session)),
    );
  }
}
