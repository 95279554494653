import { Component, OnInit } from '@angular/core';
import { Form, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { CreateRoutineRequest } from "../../../openapi/portal/models/create-routine-request";
import { intervals } from "../format-interval.pipe";
import { RoutinesService } from "../../../openapi/portal/services/routines.service";
import { ActivatedRoute } from "@angular/router";


@Component({
  selector: 'app-new-routine',
  templateUrl: './new-routine.component.html',
  styleUrl: './new-routine.component.scss'
})
export class NewRoutineComponent implements OnInit {
  newRoutine!: FormGroup;
  intervals = intervals;
  wasSaved = false;
  agentId = '';
  isLoading = false;

  constructor(private routinesService: RoutinesService,
              private route: ActivatedRoute,
              private fb: FormBuilder) { }

  ngOnInit(): void {
    this.agentId = this.route.snapshot.paramMap.get("agentId") as string;
    this.newRoutine = this.fb.group({
      agent_id: [this.agentId],
      name: ['', Validators.required],
      requirements: ['', Validators.required],
      sql_query: [''],
      interval: [this.intervals[0].value],
    });
  }

  save() {
    this.isLoading = true;
    const body: CreateRoutineRequest = this.newRoutine.value;
    this.routinesService.createRoutine({body}).subscribe((res) => {
      this.isLoading = false;
      this.wasSaved = true;
    });
  }
}
