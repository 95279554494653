<div class="integration" [class.integration--loading]="isLoading">
  <h4 class="h4">Shopify</h4>

  <section *ngIf="step === IntegrationStep.NotAttached">

    <p class="integration__description">
      Connect your Shopify store to explore your e-commerce data.
      Dive into sales analytics, customer behavior,
      and product performance to enhance your online presence and maximize profits.
    </p>

    <button class="button button--shopify button--has-icon" (click)="step = IntegrationStep.EnterShopName">
      <svg fill="none" role="img" viewBox="0 0 56 64" class="button__icon" height="64">
        <g clip-path="url(#clip0_198_267)">
          <path
            d="M48.33 12.1452C48.285 11.8315 48.015 11.6522 47.79 11.6522C47.565 11.6522 43.11 11.5626 43.11 11.5626C43.11 11.5626 39.375 7.97718 39.015 7.57382C38.655 7.21527 37.935 7.30491 37.665 7.39454C37.665 7.39454 36.945 7.61863 35.775 7.97718C35.595 7.34973 35.28 6.58782 34.875 5.7811C33.57 3.27129 31.59 1.92676 29.25 1.92676C29.07 1.92676 28.935 1.92676 28.755 1.97157C28.665 1.88194 28.62 1.7923 28.53 1.74749C27.495 0.671855 26.19 0.13404 24.615 0.178858C21.6 0.268494 18.585 2.46457 16.11 6.31891C14.4 9.05281 13.095 12.459 12.69 15.1032C9.225 16.1789 6.795 16.9408 6.705 16.9408C4.95 17.4786 4.905 17.5234 4.68 19.1817C4.59 20.4366 0 55.7979 0 55.7979L38.52 62.431L55.215 58.3077C55.17 58.3077 48.375 12.459 48.33 12.1452ZM33.84 8.60463C32.94 8.87354 31.95 9.18726 30.87 9.5458C30.87 8.02199 30.645 5.87073 29.97 4.07802C32.265 4.43656 33.39 7.036 33.84 8.60463ZM28.845 10.1284C26.82 10.7559 24.615 11.4282 22.41 12.1004C23.04 9.72508 24.21 7.39454 25.65 5.82592C26.19 5.24328 26.955 4.61583 27.81 4.25729C28.71 6.00519 28.89 8.47017 28.845 10.1284ZM24.705 2.19566C25.425 2.19566 26.01 2.33012 26.505 2.68866C25.695 3.09202 24.885 3.71947 24.165 4.5262C22.23 6.58782 20.745 9.76989 20.16 12.8623C18.315 13.445 16.515 13.9828 14.895 14.4758C15.975 9.59062 20.07 2.33012 24.705 2.19566Z"
            fill="#95BF47"></path>
          <path
            d="M47.7895 11.6526C47.5645 11.6526 43.1095 11.563 43.1095 11.563C43.1095 11.563 39.3745 7.97755 39.0145 7.57419C38.8795 7.43973 38.6995 7.3501 38.5195 7.3501V62.4313L55.2145 58.3081C55.2145 58.3081 48.4195 12.4593 48.3745 12.1456C48.2845 11.8319 48.0145 11.6526 47.7895 11.6526Z"
            fill="#5E8E3E"></path>
          <path
            d="M29.2498 20.2129L27.3148 27.4286C27.3148 27.4286 25.1548 26.4426 22.5898 26.6218C18.8098 26.8459 18.8098 29.2213 18.8098 29.8039C19.0348 33.0308 27.5398 33.7479 28.0348 41.3221C28.3948 47.2829 24.8848 51.3613 19.7548 51.6751C13.6348 51.9888 10.2598 48.4034 10.2598 48.4034L11.5648 42.8907C11.5648 42.8907 14.9848 45.4454 17.6848 45.2661C19.4398 45.1765 20.1148 43.6975 20.0248 42.7115C19.7548 38.4986 12.8248 38.7675 12.3748 31.8207C12.0148 25.9944 15.8398 20.1232 24.2998 19.5854C27.5848 19.3613 29.2498 20.2129 29.2498 20.2129Z"
            fill="white"></path>
        </g>
        <defs>
          <clipPath id="clip0_198_267">
            <rect width="225" height="64" fill="white"></rect>
          </clipPath>
        </defs>
      </svg>

      Connect Shopify
    </button>
  </section>

  <form *ngIf="step === IntegrationStep.EnterShopName" [formGroup]="shop" (ngSubmit)="save()">
    <p class="integration__description">Allow Portal to access your Shopify Account?</p>
    <p class="integration__description">
      Enter your Shopify username https://USERNAME.myshopify.com/ even if you use a custom domain like
      https://name-of-shop.com/.
      <br>
      As a reminder, your use of any Shopify services, including through Portal, is subject to Shopify's terms,
      conditions, and other policies, including, among others, the Terms of Service located at Shopify Terms of Service
      and/or
      any other terms that you have agreed to with Shopify.
    </p>
    <div class="shopify__shop">
      https://<input class="input"
                     [class.input-base--error]="shop | isInvalid: 'shop_name'"
                     type="text"
                     formControlName="shop_name"
                     placeholder="USERNAME">.myshopify.com/
    </div>
    <button class="button button--shopify">Connect</button>
  </form>

  <div class="integration" *ngIf="integration && step === IntegrationStep.Attached">

    <p>
      Shop: {{ integration.name }}
      <button class="button button--sm button--error" (click)="detach$.emit(integration)">Disconnect</button>
    </p>
    <p>
      Status:
      {{ integration.status }}
      <span *ngIf="integration.status === IntegrationStatus.AuthRequired">
      <a [href]="authUrl" *ngIf="authUrl" class="button button--sm button--shopify">Connect</a>
      <button *ngIf="!authUrl"
              [class.button--loading]="isLoading"
              class="button button--sm button--shopify"
              (click)="authenticateAgain()">
        Connect
      </button>
    </span>
    </p>
  </div>
</div>
