/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AuthenticateIntegrationResponse } from '../../models/authenticate-integration-response';

export interface AuthenticateIntegration$Params {
  integration_id: string;
}

export function authenticateIntegration(http: HttpClient, rootUrl: string, params: AuthenticateIntegration$Params, context?: HttpContext): Observable<StrictHttpResponse<AuthenticateIntegrationResponse>> {
  const rb = new RequestBuilder(rootUrl, authenticateIntegration.PATH, 'get');
  if (params) {
    rb.path('integration_id', params.integration_id, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<AuthenticateIntegrationResponse>;
    })
  );
}

authenticateIntegration.PATH = '/integrations/{integration_id}/authenticate';
