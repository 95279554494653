<div *ngIf="isLoading">Stats are loading ...</div>

<div *ngIf="!isLoading && stats.length > 0">
  <div>
    <span>Compare with:</span>
    <mat-radio-group [(ngModel)]="comparisonWith">
      <mat-radio-button [value]="ComparisonWith.Past">1 Year Ago</mat-radio-button>
      <mat-radio-button [value]="ComparisonWith.Budget">Budget</mat-radio-button>
    </mat-radio-group>
  </div>

  <table class="stats advanced-table advanced-table--full-width">
    <thead>
    <tr>
      <td>Metric</td>
      <td>Shop</td>
      <td *ngFor="let timeRange of timeRanges">{{ timeRange }}</td>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let stat of stats; let i = index">
      <tr [class.advanced-table__tr--pointer]="stat.shops.length" (click)="isOpened[i] = !isOpened[i]">
        <td [class.a--underlined]="stat.shops.length">
          {{ stat.metric.replaceAll("_", " ") | titlecase }}
        </td>
        <td>All Shops</td>
        <td *ngFor="let timeRange of timeRanges">
          {{ stat.total[timeRange].value }}
          {{ stat.total[timeRange].currency }}
          <span
            *ngIf="comparisonWith == ComparisonWith.Past"
            [style]="{color: stat.total[timeRange].change >= 0 ? 'green' : 'red'}"
            [matTooltip]="'You had ' + stat.total[timeRange].benchmark_value + stat.total[timeRange].currency + ' during this period 1 year ago'">
          (
          <span *ngIf="stat.total[timeRange].change !== 0">
            {{ stat.total[timeRange].change }} %
          </span>
            {{ stat.total[timeRange].change >= 0 ? "↑" : "↓" }}
            )
        </span>
          <span
            *ngIf="comparisonWith == ComparisonWith.Budget && stat.total[timeRange].difference_with_budget !== 0"
            [matTooltip]="'You have ' + stat.total[timeRange].budget + stat.total[timeRange].currency + ' in budget'"
            [style]="{color: stat.total[timeRange].difference_with_budget >= 0 ? 'green' : 'red'}">
          (
          <span *ngIf="stat.total[timeRange].difference_with_budget !== 0">
            {{ stat.total[timeRange].difference_with_budget }} %
          </span>
            {{ stat.total[timeRange].difference_with_budget >= 0 ? "↑" : "↓" }}
            )
        </span>
        </td>
      </tr>
      <ng-container *ngIf="isOpened[i]">
        <tr class="advanced-table__tr--accent" *ngFor="let shop of stat.shops">
          <td></td>
          <td>{{ shop.shop }}</td>
          <td *ngFor="let timeRange of timeRanges">
            {{ shop.by_time_range[timeRange].value }}
            {{ shop.by_time_range[timeRange].currency }}
            <span
              *ngIf="comparisonWith == ComparisonWith.Past"
              [matTooltip]="'You had ' + shop.by_time_range[timeRange].benchmark_value + shop.by_time_range[timeRange].currency + ' during this period 1 year ago'"
              [style]="{color: shop.by_time_range[timeRange].change >= 0 ? 'green' : 'red'}">
          (
            <span *ngIf="shop.by_time_range[timeRange].change !== 0">
              {{ shop.by_time_range[timeRange].change }}%
            </span>
              {{ shop.by_time_range[timeRange].change >= 0 ? "↑" : "↓" }}
              )
          </span>
            <span
              *ngIf="comparisonWith == ComparisonWith.Budget && shop.by_time_range[timeRange].difference_with_budget"
              [style]="{color: shop.by_time_range[timeRange].difference_with_budget >= 0 ? 'green' : 'red'}"
              [matTooltip]="'You have ' + shop.by_time_range[timeRange].budget + shop.by_time_range[timeRange].currency + ' in budget'">
          (
            <span *ngIf="shop.by_time_range[timeRange].difference_with_budget !== 0">
              {{ shop.by_time_range[timeRange].difference_with_budget }}%
            </span>
              {{ shop.by_time_range[timeRange].difference_with_budget >= 0 ? "↑" : "↓" }}
              )
        </span>
          </td>
        </tr>
      </ng-container>
    </ng-container>
    </tbody>
  </table>
</div>
