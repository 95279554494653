/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { Business } from '../models/business';
import { BusinessResponse } from '../models/business-response';
import { createBusiness } from '../fn/business/create-business';
import { CreateBusiness$Params } from '../fn/business/create-business';
import { deleteBusiness } from '../fn/business/delete-business';
import { DeleteBusiness$Params } from '../fn/business/delete-business';
import { DeleteBusinessResponse } from '../models/delete-business-response';
import { getBusiness } from '../fn/business/get-business';
import { GetBusiness$Params } from '../fn/business/get-business';
import { updateBusiness } from '../fn/business/update-business';
import { UpdateBusiness$Params } from '../fn/business/update-business';

@Injectable({ providedIn: 'root' })
export class BusinessService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getBusiness()` */
  static readonly GetBusinessPath = '/business';

  /**
   * Get Business.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBusiness()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBusiness$Response(params?: GetBusiness$Params, context?: HttpContext): Observable<StrictHttpResponse<BusinessResponse>> {
    return getBusiness(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Business.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBusiness$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBusiness(params?: GetBusiness$Params, context?: HttpContext): Observable<BusinessResponse> {
    return this.getBusiness$Response(params, context).pipe(
      map((r: StrictHttpResponse<BusinessResponse>): BusinessResponse => r.body)
    );
  }

  /** Path part for operation `updateBusiness()` */
  static readonly UpdateBusinessPath = '/business';

  /**
   * Update Business.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateBusiness()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBusiness$Response(params: UpdateBusiness$Params, context?: HttpContext): Observable<StrictHttpResponse<Business>> {
    return updateBusiness(this.http, this.rootUrl, params, context);
  }

  /**
   * Update Business.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateBusiness$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBusiness(params: UpdateBusiness$Params, context?: HttpContext): Observable<Business> {
    return this.updateBusiness$Response(params, context).pipe(
      map((r: StrictHttpResponse<Business>): Business => r.body)
    );
  }

  /** Path part for operation `createBusiness()` */
  static readonly CreateBusinessPath = '/business';

  /**
   * Create Business.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createBusiness()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createBusiness$Response(params: CreateBusiness$Params, context?: HttpContext): Observable<StrictHttpResponse<Business>> {
    return createBusiness(this.http, this.rootUrl, params, context);
  }

  /**
   * Create Business.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createBusiness$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createBusiness(params: CreateBusiness$Params, context?: HttpContext): Observable<Business> {
    return this.createBusiness$Response(params, context).pipe(
      map((r: StrictHttpResponse<Business>): Business => r.body)
    );
  }

  /** Path part for operation `deleteBusiness()` */
  static readonly DeleteBusinessPath = '/business/{business_id}';

  /**
   * Delete Business.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteBusiness()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBusiness$Response(params: DeleteBusiness$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteBusinessResponse>> {
    return deleteBusiness(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete Business.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteBusiness$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBusiness(params: DeleteBusiness$Params, context?: HttpContext): Observable<DeleteBusinessResponse> {
    return this.deleteBusiness$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteBusinessResponse>): DeleteBusinessResponse => r.body)
    );
  }

}
