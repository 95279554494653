<div class="integration" [class.integration--loading]="isLoading">
  <h4 class="h4">Google Ads</h4>

  <div *ngIf="integration; else connect">
    <p class="integration__line" *ngIf="integration.name">Account: {{ integration.name }}</p>
    <button class="button button--sm button--error"
            [class.button--loading]="isLoading"
            (click)="detach$.emit(integration)">Disconnect</button>
  </div>

  <ng-template #connect>
    <p class="integration__description">
      Connect your Google Ads to gain insights into your ad performance.
      Understand customer acquisition costs, optimize ad spend, and discover untapped markets.
      Drive smarter marketing strategies with data-driven decisions.
    </p>

    <button class="button button--has-icon button--google-ads"
            [class.button--loading]="isLoading"
            (click)="attach()">
      <img class="button__icon" src="/assets/img/google-ads.png" alt="">
      Connect Google Ads
    </button>
  </ng-template>

</div>
