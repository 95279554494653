import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Integration } from "../../../openapi/portal/models/integration";
import { CreateIntegrationRequest } from "../../../openapi/portal/models/create-integration-request";
import { IntegrationType } from "../../../openapi/portal/models/integration-type";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BusinessStateService } from "../business/business-state.service";
import { IntegrationsService } from "../../../openapi/portal/services/integrations.service";
import { AuthReturnUrlsService } from "../auth-return-urls.service";

@UntilDestroy()
@Component({
  selector: 'app-attach-amazon',
  templateUrl: './attach-amazon.component.html',
  styleUrls: ['./attach-amazon.component.scss', '../integrations/integrations.component.scss']
})
export class AttachAmazonComponent {
  @Input() integration?: Integration;
  @Output() detach$ = new EventEmitter<Integration>();
  @Output() refresh$ = new EventEmitter<void>();
  isLoading = false;

  constructor(private businessState: BusinessStateService,
              private authReturnUrlsService: AuthReturnUrlsService,
              private integrationService: IntegrationsService) {
  }

  attach() {
    const business = this.businessState.getBusiness();
    if (!business) {
      return;
    }
    const body: CreateIntegrationRequest = {
      name: "Some amazon shop",
      type: IntegrationType.Amazon,
      business_id: business._id!,
      callback_urls: this.authReturnUrlsService.getReturnUrls(IntegrationType.Amazon),
    };
    this.isLoading = true;
    this.integrationService.initIntegration({body}).pipe(
      untilDestroyed(this),
    ).subscribe(
      () => this.refresh(),
      () => this.refresh()
    );
  }

  private refresh() {
    this.refresh$.emit();
    this.isLoading = false;
  }
}
