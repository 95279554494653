import { Component, Input, OnInit } from '@angular/core';
import { IntegrationsService } from "../../../openapi/portal/services/integrations.service";
import { Integration } from "../../../openapi/portal/models/integration";
import { IntegrationType } from "../../../openapi/portal/models/integration-type";
import { startWith, Subject, switchMap, tap } from "rxjs";
import { SidenavService } from "../../sidenav/sidenav.service";
import { BusinessStateService } from "../business/business-state.service";

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrl: './integrations.component.scss'
})
export class IntegrationsComponent implements OnInit {
  integrations: Integration[] = []
  typeToIntegration: {[type in IntegrationType]?: Integration} = {}
  IntegrationType = IntegrationType;
  refresh$ = new Subject<void>();
  business$ = this.businessState.business$;
  isLoading = false;

  constructor(private integrationsService: IntegrationsService,
              private businessState: BusinessStateService,
              private sidenavService: SidenavService) {
  }

  ngOnInit() {
    this.listenForIntegrationChanges();
  }

  detach(integration: Integration) {
    if (integration === undefined) {
      return;
    }
    this.isLoading = true;
    this.integrationsService.deleteIntegration({integration_id: integration._id!}).subscribe(() => {
      this.refresh();
    });
  }

  refresh() {
    this.sidenavService.agentsUpdateRequired$.next();
    this.refresh$.next();
  }

  private listenForIntegrationChanges() {
    this.refresh$.pipe(
      startWith(undefined),
      switchMap(() => this.getIntegrations()),
    ).subscribe(() => {
      this.isLoading = false;
    });
  }

  private getIntegrations() {
    return this.integrationsService.getIntegrations().pipe(
      tap((integrations) => {
        this.integrations = integrations.integrations;
        this.typeToIntegration = {};
        this.integrations.forEach((i) => {
          this.typeToIntegration[i.type] = i;
        });
      }),
    );
  }

}
