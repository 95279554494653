import { Component, OnInit } from '@angular/core';
import { StatsService } from '../../../openapi/portal/services';
import { MetricData } from "../../../openapi/portal/models/metric-data";

type TimeRange = "24h" | "7d" | "30d";
enum ComparisonWith {
  Budget,
  Past
}

@Component({
  selector: 'app-agent-stats',
  templateUrl: './agent-stats.component.html',
  styleUrl: './agent-stats.component.scss'
})
export class AgentStatsComponent implements OnInit {
  isOpened: boolean[] = [];
  stats: Array<MetricData> = []
  isLoading = true;
  comparisonWith: ComparisonWith = ComparisonWith.Past;
  comparisonWithOptions: ComparisonWith[] = [];
  ComparisonWith = ComparisonWith;

  timeRanges: TimeRange[] = [];

  constructor(private statsService: StatsService) {
  }

  ngOnInit() {
    setTimeout(() => this.getStats(), 1000);
  }

  private getStats() {
    this.statsService.getStats().subscribe((stats) => {
      this.stats = stats.stats;
      this.timeRanges = this.stats.length > 1 ? Object.keys(this.stats[0].total) as TimeRange[] : []
      this.isOpened = this.stats.map(() => false);
      this.isLoading = false;
    }, () => this.isLoading = false);
  }
}
