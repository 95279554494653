<div class="page">
  <div class="container">

    <app-conversation [assistantNickname]="agent?.assistant_nickname"
              [assistantAvatar]="agent?.icon"
              [isLoading]="isLoading"
              [message]="message"
              [messages]="messages"
              [isReadonly]="hasError"
              [fileUploadOn]="true"
              [height]="'calc(100vh - 40px)'"
              [progress$]="progress$"
              (filesSelected$)="onFileSelected($event)"
              (sendMessage$)="sendMessage(message.value)">
    </app-conversation>

  </div>
</div>
