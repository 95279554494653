<h3 class="h3">Integrations</h3>

<div class="integrations" *ngIf="business$ | async; else noBusiness" [class.integrations--loading]="isLoading">
  <div class="section">
    <app-attach-shopify
      (detach$)="detach($event)"
      (refresh$)="refresh()"
      [integration]="typeToIntegration[IntegrationType.Shopify]">
    </app-attach-shopify>
  </div>

  <div class="section">
    <app-attach-pgql-db
      (detach$)="detach($event)"
      (refresh$)="refresh()"
      [integration]="typeToIntegration[IntegrationType.CustomPgqlDb]">
    </app-attach-pgql-db>
  </div>

  <div class="section">
    <app-attach-amazon
      (detach$)="detach($event)"
      (refresh$)="refresh()"
      [integration]="typeToIntegration[IntegrationType.Amazon]">
    </app-attach-amazon>
  </div>

  <div class="section">
    <app-attach-google-ads
      (detach$)="detach($event)"
      (refresh$)="refresh()"
      [integration]="typeToIntegration[IntegrationType.GoogleAds]">
    </app-attach-google-ads>
  </div>

</div>

<ng-template #noBusiness>
  You must fill in the business information before you can attach integrations.
</ng-template>
