import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Integration } from "../../../openapi/portal/models/integration";
import { IntegrationsService } from "../../../openapi/portal/services/integrations.service";
import { Business, CreateIntegrationRequest, IntegrationStatus, IntegrationType } from "../../../openapi/portal/models";
import { switchMap, tap } from "rxjs";
import { BusinessStateService } from "../business/business-state.service";
import { UntilDestroy } from "@ngneat/until-destroy";
import { AuthReturnUrlsService } from "../auth-return-urls.service";

enum IntegrationStep {
  NotAttached = "not-attached",
  EnterShopName = "enter-shop-name",
  Attached = "attached",
}

@UntilDestroy()
@Component({
  selector: 'app-attach-shopify',
  templateUrl: './attach-shopify.component.html',
  styleUrls: ['./attach-shopify.component.scss', '../integrations/integrations.component.scss']
})
export class AttachShopifyComponent implements OnChanges {
  @Input() integration?: Integration;
  @Output() detach$ = new EventEmitter<Integration>();
  @Output() refresh$ = new EventEmitter<void>();
  step: IntegrationStep = IntegrationStep.NotAttached;
  shop = this.formBuilder.group({
    shop_name: ['', Validators.required],
  });
  IntegrationStep = IntegrationStep;
  IntegrationStatus = IntegrationStatus;
  authUrl = "";
  isLoading = false;

  constructor(private formBuilder: FormBuilder,
              private businessState: BusinessStateService,
              private authReturnUrlsService: AuthReturnUrlsService,
              private integrationService: IntegrationsService) {
  }

  ngOnChanges() {
    this.setIntegration(this.integration);
  }

  save() {
    this.shop.markAllAsTouched();
    const business = this.businessState.getBusiness();
    if (this.shop.invalid || !business) {
      return;
    }
    const body: CreateIntegrationRequest = {
      name: this.shop.value.shop_name as string,
      type: IntegrationType.Shopify,
      business_id: business._id!,
      callback_urls: this.authReturnUrlsService.getReturnUrls(IntegrationType.Shopify),
    };
    this.isLoading = true;
    this.integrationService.initIntegration({body}).pipe(
      tap((response) => this.setIntegration(response.integration)),
      switchMap((response) => this.authenticate(response.integration._id!)),
    ).subscribe(
      () => this.refresh(),
      () => this.refresh()
    );
  }

  authenticate(integrationId: string) {
    return this.integrationService.authenticateIntegration({integration_id: integrationId}).pipe(
      tap((response) => {
        this.authUrl = response.auth_url;
        window.open(response.auth_url, "_self");
      }),
    )
  }

  authenticateAgain() {
    this.isLoading = true;
    if (this.integration === undefined) {
      return;
    }
    this.authenticate(this.integration._id!).subscribe(() => this.isLoading = false);
  }

  private setIntegration(integration?: Integration) {
    this.integration = integration;
    if (integration === undefined) {
      this.step = IntegrationStep.NotAttached;
    }
    else if ([IntegrationStatus.AuthRequired, IntegrationStatus.Ready].includes(integration.status)) {
      this.step = IntegrationStep.Attached;
    }
  }

  private refresh() {
    this.refresh$.emit();
    this.isLoading = false;
  }
}
