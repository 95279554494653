import { Injectable } from '@angular/core';
import { Business } from "../../../openapi/portal/models/business";
import { BehaviorSubject, Observable, tap } from "rxjs";
import { filter } from "rxjs/operators";
import { BusinessService } from "../../../openapi/portal/services/business.service";

@Injectable({
  providedIn: 'root'
})
export class BusinessStateService {
  private business = new BehaviorSubject<Business | undefined | null>(undefined);
  business$ = this.business.pipe(
    filter((business) => business !== undefined),
  ) as Observable<Business | null>;
  hasBusiness$ = this.business$.pipe(
    filter((business) => !!business),
  );

  constructor(private businessService: BusinessService) { }

  updateBusiness() {
    return this.businessService.getBusiness().pipe(
      tap((business) => this.setBusiness(business.business)),
    )
  }

  getBusiness() {
    return this.business.getValue();
  }

  setBusiness(business: Business | null) {
    if (business && business._id) {
      this.business.next(business);
    } else {
      this.business.next(null);
    }
  }
}
