<div class="sidenav">

  <!--  <label class="menu__btn menu__btn&#45;&#45;black menu__btn&#45;&#45;closed" (click)="close$.emit()">-->
  <!--    <span></span>-->
  <!--    <span></span>-->
  <!--    <span></span>-->
  <!--  </label>-->

  <div class="sidenav__content">
    <div class="sidenav__logo-wrap">
      <h1 class="sidenav__title">Portal AI</h1>
      <img class="sidenav__logo" src="/assets/img/portal.svg" alt="logo">
    </div>

<!--    <table class="sidenav__metrics">-->
<!--      <thead>-->
<!--        <tr>-->
<!--          <td>USD Added</td>-->
<!--          <td>Human Hours Saved</td>-->
<!--          <td>Business Health</td>-->
<!--        </tr>-->
<!--      </thead>-->
<!--      <tbody>-->
<!--        <tr>-->
<!--          <td>100$</td>-->
<!--          <td>20 hours</td>-->
<!--          <td>Good</td>-->
<!--        </tr>-->
<!--      </tbody>-->
<!--    </table>-->

    <section>
      <div *ngIf="!hasSessions">
        <div>There are no sessions yet.</div>
      </div>

      <div class="sidenav__section">
        <div class="sidenav__groups" *ngIf="agents.length">
          <div class="sidenav__group">
            <div class="sidenav__items">
              <a class="a sidenav__item"
                 *ngFor="let agent of agents; let i = index"
                 [class.sidenav__item--main]="i === 0"
                 routerLinkActive="sidenav__item--active"
                 (click)="triggerNavigation(agent.agent_id)"
                 [routerLink]="['/', agent.agent_id]">
                <img [src]="agent.icon" alt="" class="sidenav__item-icon">
                <span class="sidenav__item-text">{{ agent.assistant_nickname }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="sidenav__section sidenav__section--sessions" *ngIf="hasBusiness$ | async">
      <div class="sidenav__subtitle">Sessions</div>
      <div class="sidenav__groups" *ngIf="groupedSessions">
        <ng-container *ngFor="let group of groups">
          <div class="sidenav__group" *ngIf="groupedSessions.time_to_sessions[group].length">
            <div class="sidenav__group-name">{{ group | titlecase }}</div>
            <div class="sidenav__items">
              <a class="a sidenav__item sidenav__item--small"
                 *ngFor="let session of groupedSessions.time_to_sessions[group]"
                 routerLinkActive="sidenav__item--active"
                 [queryParams]="{sessionId: session.id}"
                 [routerLink]="['/', session.agent.agent_id, 'session']">
                <img [src]="session.agent.icon" alt="" class="sidenav__item-icon">
                <span class="sidenav__item-text">{{ session.name }}</span>
                <!--                <img src="/assets/img/refresh.png" matTooltip="Was created by a routine" class="sidenav__routine">-->
              </a>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

  </div>
  <div class="sidenav__user">
    <app-user></app-user>
  </div>
</div>
